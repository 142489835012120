import React from 'react';
import Tpay from '../components/tpay/Tpay';

const PaymentForm = () => {
    return (
        <div>
                <Tpay/>
        </div>
    );
};

export default PaymentForm;