import { useState, createContext } from "react";
import { useCookies } from "react-cookie";

import axios from "axios";

export const StoreContext = createContext();

const StoreProvider = ({ children }) => {
    //url params
    const urlParams = new URL(document.location).searchParams;

    //navigation
    // if (!sessionStorage.getItem("initialHistoryLength")) {
    //     sessionStorage.setItem("initialHistoryLength", window.history.length);
    // }

    //auth
    const [isUserLogged, setIsUserLogged] = useState(false);
    const [cookies] = useCookies();

    //axios instances
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_BASEURL,
        headers: {
            "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_KEY,
            "Ocp-Apim-Trace": true,
            Authorization: `Bearer ${cookies.token}`,
            "Content-Type": "multipart/form-data",
            "Accept-Language": urlParams.get("lang") ?? "pl",
        },
    });

    return (
        <StoreContext.Provider
            value={{
                isUserLogged,
                setIsUserLogged,
                api,
                // language: urlParams.get("lang") ?? "pl",
            }}
        >
            {children}
        </StoreContext.Provider>
    );
};

export default StoreProvider;
