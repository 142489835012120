const Checkbox = ({ className, isChecked, isDisabled, onChangeFn, children }) => {
    const onCheckboxChange = (e) => {
        if (!onChangeFn || isDisabled) {
            return false;
        }

        onChangeFn(e.target.checked);
    };

    return (
        <>
            <label
                className={
                    "checkbox" +
                    (className ? ' ' + className : '') +
                    (isChecked ? " is-checked" : "") +
                    (isDisabled ? " is-disabled" : "")
                }
            >
                <input
                    className="checkbox-input"
                    type="checkbox"
                    checked={isChecked}
                    disabled={isDisabled}
                    onChange={onCheckboxChange}
                />
                <span className="checkbox-field">
                    <span className="checkbox-mark">
                        <span className="checkbox-icon"></span>
                    </span>
                    <span className="checkbox-text">
                        {typeof children === "object"
                            ? children.length > 1
                                ? children.map((object) => {
                                      return object;
                                  })
                                : children
                            : children}
                    </span>
                </span>
            </label>
        </>
    );
};

export default Checkbox;
