import Wrapper from "../Wrapper";

const SigningFormContainer = ({
    wrapperClass,
    groupClass,
    additionalWrapperClass,
    children,
}) => {
    const inputWrapper = (o, i) => (
        <div
            className={
                wrapperClass
                    ? wrapperClass + "-form-field"
                    : "default-form-field"
            }
            key={"from-input_" + i}
        >
            {o}
        </div>
    );
    const checkboxWrapper = (o, i) => (
        <div
            className={
                wrapperClass
                    ? wrapperClass + "-form-checkbox"
                    : "default-form-checkbox"
            }
            key={"form-checkbox_" + i}
        >
            {o}
        </div>
    );

    return (
        <Wrapper
            condition={true}
            wrapper={(children) => (
                <div
                    className={
                        (wrapperClass ? wrapperClass : "default") +
                        "-form" +
                        (groupClass ? "-" + groupClass : "-group") +
                        (additionalWrapperClass
                            ? " " + additionalWrapperClass
                            : "")
                    }
                >
                    {children}
                </div>
            )}
        >
            {children.length > 1
                ? children.map((object, index) => {
                    if (object.type === undefined) {
                        return false;
                    }

                    if (object.key === "Input" || object.key === "Button") {
                        return inputWrapper(object, index);
                    } else if (object.type.name === "Checkbox") {
                        return checkboxWrapper(object, index);
                    } else {
                        return object;
                    }
                })
                : children}
        </Wrapper>
    );
};

export default SigningFormContainer;
