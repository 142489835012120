import Logo from "../headings/Logo";

const BoxContainer = ({additionalClass, contentClass, children}) => {
    return (
        <>
            <div className={'box-container' + (additionalClass ? (' ' + additionalClass) : '')}>
                <div className="box-wrapper">
                    <div className="box-dialog">
                        <Logo wrapperClass="box-logo"/>
                        <div className={'box-content' + (contentClass ? (' ' + contentClass) : '')}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BoxContainer;