function formDataFn(formData, data, parentKey) {
    if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
    ) {
        Object.keys(data).forEach((key) => {
            formDataFn(
                formData,
                data[key],
                parentKey ? `${parentKey}[${key}]` : key
            );
        });
    } else {
        const value = data == null ? "" : data;
        formData.append(parentKey, value);
    }
}

const buildFormData = (data) => {
    const formData = new FormData();
    formDataFn(formData, data);
    return formData;
};

export default buildFormData;
