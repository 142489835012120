import { useContext } from "react";
import { useHistory } from "react-router-dom";

import { StoreContext } from "../context/Store";

/**
 *
 *
 * @param {string} redirectTo Redirect path
 * @param {boolean} userLogged True when you want to redirect when user IS logged in, false otherwise
 */
const useProtectedRoute = (redirectTo, userLogged) => {
    const { isUserLogged } = useContext(StoreContext);

    const history = useHistory();

    if (userLogged) {
        if (isUserLogged) {
            history.replace(redirectTo);
        }
    } else {
        if (!isUserLogged) {
            history.replace(redirectTo);
        }
    }
};

export default useProtectedRoute;
