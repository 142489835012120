import { useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

import { StoreContext } from "../../context/Store";

import buildFormData from "../../functions/buildFormData";
import concatenateCreditCardData from "./functions/concatenateCreditCardData";
import encodeCreditCardData from "./functions/encodeCreditCardData";

import TpayCard from "./TpayCard";
import TpayForm from "./TpayForm";
import TpayLogo from "./TpayLogo";
import Loader from "../loader/Loader";

const Tpay = ({ user, separator, onError, onCancelFn }) => {
  const { api } = useContext(StoreContext);

  const history = useHistory();
  const setCookie = useCookies("paymentRedirect")[1];

  const childrenListQuery = useQuery(
    "childrenList",
    () => api.get(`/account/childList`),
    {
      retry: false,
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const makeArrayOfChosenSubscriptionIds = () => {
    const arrayOfChosenSubscripitonIds = [];
    childrenListQuery.data.data.childs.forEach((element) => {
      if (element.subscription.status === "waiting") {
        arrayOfChosenSubscripitonIds.push(element.subscription.subscription_id);
      }
    });
    return arrayOfChosenSubscripitonIds;
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleMakePayment = (data) => {
    if (!data) {
      setIsSubmitting(false);
    } else {
      setCookie("paymentRedirect", true, { path: "/" });

      if (data[0].err_desc) {
        history.push("dashboard/paymentError");
      } else {
        if (data[0].redirect_url) {
          window.location.replace(data[0].redirect_url);
        } else {
          history.push("dashboard/paymentConfirm");
        }
      }
    }
  };

  const { mutate: makePaymentMutate } = useMutation(
    () =>
      api.post(
        `/process`,
        buildFormData({
          carddata: encodeCreditCardData(
            concatenateCreditCardData(
              values.number,
              values.month,
              values.year,
              values.cvv
            )
          ),
          subscription_id: makeArrayOfChosenSubscriptionIds(),
          // card_vendor: "visa",
          card_save: "on",
        })
        //
        // card_save: "on"
      ),
    {
      onMutate: () => setIsSubmitting(true),
      onSuccess: (data) => handleMakePayment(data.data),
      onError: (data) => {
        setIsSubmitting(false);
        onError();
      },
    }
  );

  const [values, setValues] = useState({
    number: "",
    month: "",
    year: "",
    cvv: "",
    agreement: false,
  });

  const [validations, setValidations] = useState({
    number: "",
    month: "",
    year: "",
    cvv: "",
  });

  const onUpdateHandler = (e) => {
    setValues({ ...values, ...e.value });
    setValidations({ ...validations, ...e.validation });
  };

  const submitEnabled =
    validations.number === "success" &&
    validations.number === "success" &&
    validations.year === "success" &&
    validations.cvv === "success" &&
    values.agreement
      ? true
      : false;

  return (
    <>
      <div className="tpay-container">
        <TpayLogo />
        <TpayCard
          values={values}
          user={user}
          separator={separator}
          isSubmitting={isSubmitting}
        />
        <TpayForm
          values={values}
          validations={validations}
          separator={separator}
          submit={submitEnabled}
          isDisabled={isSubmitting}
          onUpdate={(e) => onUpdateHandler(e)}
          onSubmit={() => {
            makePaymentMutate();
          }}
          onCancel={() => onCancelFn()}
        />
      </div>
      {isSubmitting && (
        <div className="tpay-loader">
          <Loader color="white" />
        </div>
      )}
    </>
  );
};

export default Tpay;
