const SigningValidationContainer = ({ isActive, text, children }) => {
    return (
        <>
            {
                isActive
                && <div className="signing-form-validation text-center">
                    {
                        text
                        && <p className="validation-message">* {text}</p>
                    }
                    {children}
                </div>
            }
        </>
    )
}

export default SigningValidationContainer;