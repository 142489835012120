const DashboardNotification = ({text, children}) => {
    return (
        <>
            <div className="dashboard-notification">
                <div className="notification-text">{text}</div>
                <div className="notification-button">{children}</div>
                <div className="notification-text text-right">{text}</div>
            </div>
        </>
    )
}

export default DashboardNotification;