import { useState } from 'react';

import BoxContainer from "../components/containers/BoxContainer";

import ResetPasswordCodeForm from "../components/forms/signing/ResetPasswordCodeForm";
import ResetPasswordForm from "../components/forms/signing/ResetPasswordForm";

const ResetPassword = () => {
    const [resetFormActive, setResetFormActive] = useState(false);
    const [confirmation, setConfirmation] = useState()

    return (
        <>
            <BoxContainer contentClass="signing">
                {
                    resetFormActive
                        ? <ResetPasswordForm confirmation={confirmation} />
                        : <ResetPasswordCodeForm setResetFormActive={setResetFormActive} setConfirmation={setConfirmation} />
                }
            </BoxContainer>
        </>
    )
}

export default ResetPassword;