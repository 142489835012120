import Wrapper from '../containers/Wrapper';

import arrowIcon from '../../assets/images/btn-backwards_icon.png';

const Title = ({subtitle, additionalClass, buttonBackwards, backOnClickFn, children}) => {
    return (
        <Wrapper
            condition={subtitle}
            wrapper={children => <div className={'heading-default' + (additionalClass ? (' ') + additionalClass : '')}>{children}</div>}
        >
            <h1 className={'title-default' + (additionalClass && !subtitle ? (' ' + additionalClass) : '') + (buttonBackwards ? ' has-button' : '')}>
                {
                    buttonBackwards
                    && <button className="btn-backwards" type="button" onClick={backOnClickFn}><img src={arrowIcon} alt=""/></button>
                }
                {children}
            </h1>
            {
                subtitle
                && <h2 className="subtitle-default h1">{subtitle}</h2>
            }
        </Wrapper>
    )
}

export default Title;