import { useState, useRef, useContext, useEffect } from "react";
import { StoreContext } from "../context/Store";
import { setLogger, useQuery } from "react-query";

import { CSSTransition } from "react-transition-group";

import Modal from "../components/modals/Modal";
import ModalButtons from "../components/containers/ModalButtons";
import ModalDoodle from "../components/containers/ModalDoodle";
import Title from "../components/headings/Title";
import Button from "../components/buttons/Button";
import Input from "../components/inputs/Input";
import Textarea from "../components/inputs/Textarea";

import DashboardContainer from "../components/containers/dashboard/DashboardContainer";
import DashboardNotification from "../components/containers/dashboard/DashboardNotification";
import DashboardPanel from "../components/containers/dashboard/DashboardPanel";
import DashboardTable from "../components/containers/dashboard/DashboardTable";
import DashboardTableCell from "../components/containers/dashboard/DashboardTableCell";
import DashboardChildrensContainer from "../components/containers/dashboard/DashboardChildrensContainer";
import DashboardChildren from "../components/containers/dashboard/DashboardChildren";
import DashboardPaymentContainer from "../components/containers/dashboard/DashboardPaymentContainer";
import DashboardDataForm from "../components/containers/dashboard/DashboardDataForm";
import DashboardDataFill from "../components/containers/dashboard/DashboardDataFill";

import doodleNotify_1 from "../assets/images/dashboard/notify/doodle-notify_1.svg";
import doodleNotify_2 from "../assets/images/dashboard/notify/doodle-notify_2.svg";
import doodleNotify_3 from "../assets/images/dashboard/notify/doodle-notify_3.svg";
import useProtectedRoute from "../hooks/useProtetedRoute";
import buildFormData from "../functions/buildFormData";

import Tpay from "../components/tpay/Tpay";
import Loader from "../components/loader/Loader";
import InputMasked from "../components/inputs/InputMasked";
import { useCookies } from "react-cookie";
import TpayError from "../components/tpay/TpayError";

const Dashboard = () => {
    useProtectedRoute("/", false);

    const { api } = useContext(StoreContext);

    const [isLoading, setIsLoading] = useState(true);
    const [children, setChildren] = useState(null);
    const [childrenElements, setChildrenElements] = useState(null);
    const [paymentProblems, setPaymentProblems] = useState(false);
    const [payment, setPayment] = useState(0);
    const [user, setUser] = useState();
    const [invoice, setInvoice] = useState();
    const [isPaymentProcessActive, setIsPaymentProcessActive] = useState(false);
    const [isAboChanged, setisAboChanged] = useState(false);
    const [isAboActivated, setisAboActivated] = useState(false);
    const [isAboCanceled, setIsAboCanceled] = useState(false);
    const [isInvoiceLoading, setisInvoiceLoading] = useState(false);

    const { data: userData, refetch: dataRefetch } = useQuery(
        "userData",
        () => api.get("/account"),
        {
            retry: false,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        }
    );

    const { refetch: privateInvoiceDataSave } = useQuery(
        "invoiceData",
        () =>
            api.post(
                `/account/invoice/save`,
                buildFormData({
                    account_type: "private",
                    name: invoiceValue.private_name,
                    surname: invoiceValue.private_surname,
                    zipCode: invoiceValue.private_zipCode,
                    city: invoiceValue.private_city,
                    address: invoiceValue.private_address,
                })
            ),
        {
            retry: false,
            enabled: false,
            refetchOnWindowFocus: false,
            onSuccess: () => {
                setTimeout(() => {
                    dataRefetch();
                    if (isPaymentProcessActive) {
                        setTpayModalActive(true);
                    }
                }, 200);
            },
        }
    );
    const { refetch: businessInvoiceDataSave } = useQuery(
        "invoiceData",
        () =>
            api.post(
                `/account/invoice/save`,
                buildFormData({
                    company_name: invoiceValue.company_name,
                    nip: invoiceValue.company_nip,
                    account_type: "company",
                    company_zipCode: invoiceValue.company_zipCode,
                    company_city: invoiceValue.company_city,
                    company_address: invoiceValue.company_address,
                })
            ),
        {
            retry: false,
            enabled: false,
            refetchOnWindowFocus: false,
            onSuccess: () => {
                setTimeout(() => {
                    dataRefetch();
                    if (isPaymentProcessActive) {
                        setTpayModalActive(true);
                    }
                }, 200);
            },
        }
    );

    const { data: childrenList, refetch: getChildren } = useQuery(
        "childrenList",
        () => api.get(`/account/childList`),
        {
            refetchInterval: 10000,
        }
    );

    useEffect(() => {
        const chil = childrenList?.data?.childs.map((child) => child);
        // childrenList.data.childs.map(child => <DashboardChildren key={child.idUser} child={child} />) : null;
        setChildren(chil);
    }, [childrenList]);

    useEffect(() => {
        const chil = children
            ? children?.map((child) => (
                  <DashboardChildren
                      key={child.idUser}
                      child={child}
                      childrenList={children}
                      setChildren={setChildren}
                      refetch={dataRefetch}
                      setIsAboChanged={setisAboChanged}
                      setIsAboActivated={setisAboActivated}
                      setIsAboCanceled={setIsAboCanceled}
                  />
              ))
            : null;

        setChildrenElements(chil);

        const paymentValues = children?.map((child) => {
            if (child.subscription.status === "waiting") {
                return Number(child.subscription.price);
            } else return 0;
        });

        if (paymentValues?.length > 0) {
            setPayment(paymentValues?.reduce((value, prev) => prev + value));
        }
    }, [children]);

    useEffect(() => {
        setUser(userData?.data?.user);
        setInvoice(userData?.data?.invoice);
    }, [userData]);

    /* PANEL DATA TABS */
    const [userDataTabs, setUserDataTabs] = useState({
        person: true,
        business: false,
    });

    const panelTabSwitch = async (e) => {
        const tempKeys = Object.keys(userDataTabs);
        const switchTarget = e.currentTarget.getAttribute("switchtarget");
        let tempDataTabs = {};

        for (const key of tempKeys) {
            if (key === switchTarget) {
                tempDataTabs = { ...tempDataTabs, [key]: true };
                setAccountType("company");
            } else {
                tempDataTabs = { ...tempDataTabs, [key]: false };
                setAccountType("private");
            }
        }

        setUserDataTabs(tempDataTabs);
    };

    /* PANEL DATA MODALS */
    const [personModalActive, setPersonModalActive] = useState(false);
    const [businessModalActive, setBusinessModalActive] = useState(false);

    const personModalRef = useRef(null);
    const businessModalRef = useRef(null);

    /* ACCOUNT TYPE */
    const [accountType, setAccountType] = useState("");

    /* ACCOUNT VALUES */
    const [invoiceValue, setInvoiceValue] = useState({
        private_name: "",
        private_surname: "",
        private_zipCode: "",
        private_city: "",
        private_address: "",

        company_name: "",
        company_nip: "",
        company_zipCode: "",
        company_city: "",
        company_address: "",
    });

    /* ACCOUNT VALIDATORS */
    const [invoiceValidator, setInvoiceValidator] = useState({
        private_name: {
            response: "",
        },
        private_surname: {
            response: "",
        },
        private_zipCode: {
            response: "",
        },
        private_city: {
            response: "",
        },
        private_address: {
            response: "",
        },

        company_name: {
            response: "",
        },
        company_nip: {
            response: "",
        },
        company_zipCode: {
            response: "",
        },
        company_city: {
            response: "",
        },
        company_address: {
            response: "",
        },
    });

    /* INPUT ON CHANGE HANDLER */
    const invoiceInputOnChangeHandler = (e, key, minLength) => {
        setInvoiceValue({ ...invoiceValue, [key]: e });

        if (minLength) {
            if (minLength <= e.length) {
                setInvoiceValidator({
                    ...invoiceValidator,
                    [key]: {
                        response: "success",
                        errorMessage: "",
                    },
                });
            } else {
                if (e.length === 0) {
                    setInvoiceValidator({
                        ...invoiceValidator,
                        [key]: {
                            response: "",
                            errorMessage: "",
                        },
                    });
                } else {
                    setInvoiceValidator({
                        ...invoiceValidator,
                        [key]: {
                            response: "error",
                            errorMessage: "",
                        },
                    });
                }
            }
        } else {
            setInvoiceValidator({
                ...invoiceValidator,
                [key]: {
                    response: "success",
                    errorMessage: "",
                },
            });
        }
    };

    /* FILL ACCOUNT DATA */
    useEffect(() => {
        setAccountType(userData?.data?.invoice?.account_type);

        const private_name = userData?.data?.invoice?.name;
        const private_surname = userData?.data?.invoice?.surname;
        const private_zipCode = userData?.data?.invoice?.zipCode;
        const private_city = userData?.data?.invoice?.city;
        const private_address = userData?.data?.invoice?.address;

        const company_name = userData?.data?.invoice?.company_name;
        const company_nip = userData?.data?.invoice?.nip;
        const company_zipCode = userData?.data?.invoice?.company_zipCode;
        const company_city = userData?.data?.invoice?.company_city;
        const company_address = userData?.data?.invoice?.company_address;

        setInvoiceValue({
            private_name: private_name ?? "",
            private_surname: private_surname ?? "",
            private_zipCode: private_zipCode ?? "",
            private_city: private_city ?? "",
            private_address: private_address ?? "",

            company_name: company_name ?? "",
            company_nip: company_nip ?? "",
            company_zipCode: company_zipCode ?? "",
            company_city: company_city ?? "",
            company_address: company_address ?? "",
        });

        setInvoiceValidator({
            private_name: {
                response: private_name ? "success" : "error",
            },
            private_surname: {
                response: private_surname ? "success" : "error",
            },
            private_zipCode: {
                response: private_zipCode ? "success" : "error",
            },
            private_city: {
                response: private_city ? "success" : "error",
            },
            private_address: {
                response: private_address ? "success" : "error",
            },

            company_name: {
                response: company_name ? "success" : "error",
            },
            company_nip: {
                response: company_nip ? "success" : "error",
            },
            company_zipCode: {
                response: company_zipCode ? "success" : "error",
            },
            company_city: {
                response: company_city ? "success" : "error",
            },
            company_address: {
                response: company_address ? "success" : "error",
            },
        });

        if (userData?.data?.user?.idRank === 2) {
            getChildren();
        }

        if (userData?.data?.invoice?.account_type === "private") {
            setUserDataTabs({ person: true, business: false });
        } else if (userData?.data?.invoice?.account_type === "company") {
            setUserDataTabs({ person: false, business: true });
        }
    }, [userData]);

    useEffect(async () => {
        if (accountType) {
            await api.post(
                `/account/invoice/type`,
                buildFormData({
                    account_type: accountType,
                })
            );
        }
    }, [accountType]);

    /* INVOICE SUBMIT VALIDATORS */
    const invoicePrivateSubmitDisable =
        invoiceValidator.private_name.response === "success" &&
        invoiceValidator.private_surname.response === "success" &&
        invoiceValidator.private_zipCode.response === "success" &&
        invoiceValidator.private_city.response === "success" &&
        invoiceValidator.private_address.response === "success"
            ? false
            : true;

    const invoiceCompanySubmitDisable =
        invoiceValidator.company_name.response === "success" &&
        invoiceValidator.company_nip.response === "success" &&
        invoiceValidator.company_zipCode.response === "success" &&
        invoiceValidator.company_city.response === "success" &&
        invoiceValidator.company_address.response === "success"
            ? false
            : true;

    /* HANDLE GO TO APP */
    const handleGoToApp = () => {
        window.open("https://d-how2edu-fe-weu-app01.azurewebsites.net/");
    };

    /* HANDLE PAYMENT */
    const handlePayment = () => {
        setIsPaymentProcessActive(true);
        if (accountType === "private") {
            if (!invoiceValue.private_name || !invoiceValue.private_surname) {
                setPersonModalActive(true);
            } else {
                setTpayModalActive(true);
                setIsPaymentProcessActive(false);
            }
        } else {
            if (
                !invoiceValue.company_name ||
                !invoiceValue.company_nip ||
                !invoiceValue.company_address
            ) {
                setBusinessModalActive(true);
            } else {
                setTpayModalActive(true);
                setIsPaymentProcessActive(false);
            }
        }
    };

    /* TPAY MODAL */
    const [tpayModalActive, setTpayModalActive] = useState(false);
    const tpayModalRef = useRef(null);

    useEffect(() => {
        setisInvoiceLoading(false);
    }, [invoice]);

    useEffect(() => {
        if (
            !userData ||
            !invoice ||
            (userData?.data?.user?.idRank === 2 && !children)
        ) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    });

    /* TPAY ERROR MODAL */
    const [tpayModalErrorActive, setTpayModalErrorActive] = useState(false);
    const tpayModalErrorRef = useRef(null);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <DashboardContainer>
                {paymentProblems ? (
                    <DashboardNotification text="Problem z płatnością">
                        <Button additionalClass="btn-white btn-medium btn-fixedSize">
                            Sprawdź
                        </Button>
                    </DashboardNotification>
                ) : null}
                <DashboardPanel title="MOJE KONTO" additionalClass="large">
                    <DashboardTable>
                        <DashboardTableCell title="Imię">
                            {userData && user?.name}
                        </DashboardTableCell>
                        <DashboardTableCell title="Nazwisko">
                            {userData && user?.surname}
                        </DashboardTableCell>
                        <DashboardTableCell title="E-mail">
                            {userData && user?.email}
                        </DashboardTableCell>
                        <DashboardTableCell title="Hasło">
                            ************
                        </DashboardTableCell>
                        <DashboardTableCell title="Telefon">
                            {userData && user?.phone}
                        </DashboardTableCell>
                        <DashboardTableCell title="Miejscowość">
                            {userData && user?.city}
                        </DashboardTableCell>
                    </DashboardTable>
                </DashboardPanel>
                {!accountType ? (
                    <div>*Placeholder* Loading...(user data)</div>
                ) : (
                    <DashboardPanel
                        title="DANE DO FAKTURY"
                        additionalClass="doodle"
                        switches={{
                            person: {
                                name: "Osoba prywatna",
                                fn: panelTabSwitch,
                                active: userDataTabs.person,
                            },
                            business: {
                                name: "Firma",
                                fn: panelTabSwitch,
                                active: userDataTabs.business,
                            },
                        }}
                    >
                        {userDataTabs.person &&
                            (!isInvoiceLoading ? (
                                <DashboardTable
                                    button={{
                                        text: "Zmień dane do faktury",
                                        fn: () => {
                                            setIsPaymentProcessActive(false);
                                            setPersonModalActive(true);
                                        },
                                    }}
                                >
                                    <DashboardTableCell title="Imię">
                                        {userData && invoice?.name}
                                    </DashboardTableCell>
                                    <DashboardTableCell title="Nazwisko">
                                        {userData && invoice?.surname}
                                    </DashboardTableCell>
                                    <DashboardTableCell title="Kod pocztowy">
                                        {userData && invoice?.zipCode}
                                    </DashboardTableCell>
                                    <DashboardTableCell title="Miasto">
                                        {userData && invoice?.city}
                                    </DashboardTableCell>
                                    <DashboardTableCell title="Ulica">
                                        {userData && invoice?.address}
                                    </DashboardTableCell>
                                </DashboardTable>
                            ) : (
                                <Loader />
                            ))}
                        {userDataTabs.business &&
                            (!isInvoiceLoading ? (
                                <DashboardTable
                                    button={{
                                        text: "Zmień dane do faktury",
                                        fn: () => setBusinessModalActive(true),
                                    }}
                                >
                                    <DashboardTableCell title="Nazwa firmy">
                                        {userData && invoice?.company_name}
                                    </DashboardTableCell>
                                    <DashboardTableCell title="NIP">
                                        {userData && invoice?.nip}
                                    </DashboardTableCell>
                                    <DashboardTableCell title="Kod pocztowy">
                                        {userData && invoice?.company_zipCode}
                                    </DashboardTableCell>
                                    <DashboardTableCell title="Miasto">
                                        {userData && invoice?.company_city}
                                    </DashboardTableCell>
                                    <DashboardTableCell title="Ulica">
                                        {userData && invoice?.company_address}
                                    </DashboardTableCell>
                                </DashboardTable>
                            ) : (
                                <Loader />
                            ))}
                    </DashboardPanel>
                )}

                <DashboardChildrensContainer
                    titles={{
                        primary: "MOJE DZIECI",
                        secondary: "(Zarządzaj abonamentami)",
                    }}
                >
                    {children?.length > 0 ? (
                        childrenElements
                    ) : (
                        <DashboardPanel additionalClass="no-childrens" panel>
                            <p>Nie masz jeszcze zarejestrowanego dziecka.</p>
                            <p>Dodaj dziecko w aplikacji How2 Edu.</p>
                            <div className="noChildrens-doodle"></div>
                            <Button
                                onClickFn={() => handleGoToApp()}
                                wrapperClass="noChildrens-button"
                                additionalClass="btn-medium btn-fixedSize"
                            >
                                Przejdź do aplikacji
                            </Button>
                        </DashboardPanel>
                    )}
                </DashboardChildrensContainer>
                {children?.length > 0 ? (
                    <>
                        {payment ? (
                            <DashboardPanel additionalClass="content">
                                Kwota do zapłaty: {payment} zł
                            </DashboardPanel>
                        ) : null}
                        <DashboardPanel additionalClass="payment">
                            <DashboardPaymentContainer text="Przejdź do płatności lub wybierz abonament dla następnego dziecka">
                                <Button
                                    additionalClass="btn-ultraLarge btn-inverse highlight-alt"
                                    isDisabled={!payment}
                                    onClickFn={() => handlePayment()}
                                >
                                    <span>PRZEJDŹ DO PŁATNOŚCI</span>
                                    <span>(przejdziesz do płatności Tpay)</span>
                                </Button>
                            </DashboardPaymentContainer>
                        </DashboardPanel>
                    </>
                ) : null}
            </DashboardContainer>
            {/* DATA CHANGE MODALS */}
            <CSSTransition
                nodeRef={personModalRef}
                in={personModalActive}
                classNames="app-modal"
                timeout={300}
                unmountOnExit
                appear
            >
                <Modal ref={personModalRef} additionalClass="modal-data">
                    <Title additionalClass="text-center">
                        EDYTUJ DANE DO FAKTURY
                    </Title>
                    <DashboardDataForm>
                        <DashboardDataFill text="Imię">
                            <Input
                                additionalClass="is-square"
                                inputValue={invoiceValue.private_name}
                                onChangeFn={(e) =>
                                    invoiceInputOnChangeHandler(
                                        e.currentTarget.value,
                                        "private_name",
                                        3
                                    )
                                }
                                isValidated={{
                                    response:
                                        invoiceValidator.private_name.response,
                                }}
                            />
                        </DashboardDataFill>
                        <DashboardDataFill text="Nazwisko">
                            <Input
                                additionalClass="is-square"
                                inputValue={invoiceValue.private_surname}
                                onChangeFn={(e) =>
                                    invoiceInputOnChangeHandler(
                                        e.currentTarget.value,
                                        "private_surname",
                                        3
                                    )
                                }
                                isValidated={{
                                    response:
                                        invoiceValidator.private_surname
                                            .response,
                                }}
                            />
                        </DashboardDataFill>
                        <DashboardDataFill text="Kod pocztowy">
                            <InputMasked
                                classNameWrapper="is-square"
                                key="InputMasked"
                                value={invoiceValue.private_zipCode}
                                placeholder="wpisz kod pocztowy"
                                placeholderFocus="00-000"
                                fields={[{ rows: 2 }, { rows: 3 }]}
                                separator="-"
                                isValid={
                                    invoiceValidator.private_zipCode.response
                                }
                                onChangeFn={(e) => {
                                    setInvoiceValue((prevValue) => ({
                                        ...prevValue,
                                        private_zipCode: e.value,
                                    }));

                                    setInvoiceValidator((prevValidator) => ({
                                        ...prevValidator,
                                        private_zipCode: {
                                            response: e.response,
                                        },
                                    }));
                                }}
                            />
                        </DashboardDataFill>
                        <DashboardDataFill text="Miasto">
                            <Input
                                additionalClass="is-square"
                                inputValue={invoiceValue.private_city}
                                isValidated={{
                                    response:
                                        invoiceValidator.private_city.response,
                                }}
                                onChangeFn={(e) =>
                                    invoiceInputOnChangeHandler(
                                        e.currentTarget.value,
                                        "private_city",
                                        3
                                    )
                                }
                            />
                        </DashboardDataFill>
                        <DashboardDataFill text="Ulica">
                            <Input
                                additionalClass="is-square"
                                inputValue={invoiceValue.private_address}
                                isValidated={{
                                    response:
                                        invoiceValidator.private_address
                                            .response,
                                }}
                                onChangeFn={(e) =>
                                    invoiceInputOnChangeHandler(
                                        e.currentTarget.value,
                                        "private_address",
                                        3
                                    )
                                }
                            />
                        </DashboardDataFill>
                    </DashboardDataForm>
                    <ModalButtons>
                        <Button
                            additionalClass="btn-medium btn-gray"
                            onClickFn={() => setPersonModalActive(false)}
                        >
                            Anuluj
                        </Button>
                        <Button
                            additionalClass="btn-medium disabled-alt"
                            onClickFn={() => {
                                privateInvoiceDataSave();
                                setPersonModalActive(false);
                                setisInvoiceLoading(true);
                            }}
                            isDisabled={invoicePrivateSubmitDisable}
                        >
                            Zapisz
                        </Button>
                    </ModalButtons>
                </Modal>
            </CSSTransition>
            <CSSTransition
                nodeRef={businessModalRef}
                in={businessModalActive}
                classNames="app-modal"
                timeout={300}
                unmountOnExit
                appear
            >
                <Modal ref={businessModalRef} additionalClass="modal-data">
                    <Title additionalClass="text-center">
                        EDYTUJ DANE DO FAKTURY
                    </Title>
                    <DashboardDataForm>
                        <DashboardDataFill text="Nazwa firmy">
                            <Input
                                additionalClass="is-square"
                                inputValue={invoiceValue.company_name}
                                onChangeFn={(e) =>
                                    invoiceInputOnChangeHandler(
                                        e.currentTarget.value,
                                        "company_name",
                                        3
                                    )
                                }
                                isValidated={{
                                    response:
                                        invoiceValidator.company_name.response,
                                }}
                            />
                        </DashboardDataFill>
                        <DashboardDataFill text="NIP">
                            <Input
                                additionalClass="is-square"
                                inputValue={invoiceValue.company_nip}
                                onChangeFn={(e) =>
                                    invoiceInputOnChangeHandler(
                                        e.currentTarget.value,
                                        "company_nip",
                                        10
                                    )
                                }
                                isValidated={{
                                    response:
                                        invoiceValidator.company_nip.response,
                                }}
                            />
                        </DashboardDataFill>
                        <DashboardDataFill text="Kod pocztowy">
                            <InputMasked
                                classNameWrapper="is-square"
                                key="InputMasked"
                                value={invoiceValue.company_zipCode}
                                placeholder="wpisz kod pocztowy"
                                placeholderFocus="00-000"
                                fields={[{ rows: 2 }, { rows: 3 }]}
                                separator="-"
                                isValid={
                                    invoiceValidator.company_zipCode.response
                                }
                                onChangeFn={(e) => {
                                    setInvoiceValue((prevValue) => ({
                                        ...prevValue,
                                        company_zipCode: e.value,
                                    }));

                                    setInvoiceValidator((prevValidator) => ({
                                        ...prevValidator,
                                        company_zipCode: {
                                            response: e.response,
                                        },
                                    }));
                                }}
                            />
                        </DashboardDataFill>
                        <DashboardDataFill text="Miasto">
                            <Input
                                additionalClass="is-square"
                                inputValue={invoiceValue.company_city}
                                isValidated={{
                                    response:
                                        invoiceValidator.company_city.response,
                                }}
                                onChangeFn={(e) =>
                                    invoiceInputOnChangeHandler(
                                        e.currentTarget.value,
                                        "company_city",
                                        3
                                    )
                                }
                            />
                        </DashboardDataFill>
                        <DashboardDataFill text="Ulica">
                            <Input
                                additionalClass="is-square"
                                inputValue={invoiceValue.company_address}
                                isValidated={{
                                    response:
                                        invoiceValidator.company_address
                                            .response,
                                }}
                                onChangeFn={(e) =>
                                    invoiceInputOnChangeHandler(
                                        e.currentTarget.value,
                                        "company_address",
                                        3
                                    )
                                }
                            />
                        </DashboardDataFill>
                    </DashboardDataForm>
                    <ModalButtons>
                        <Button
                            additionalClass="btn-medium btn-gray"
                            onClickFn={() => setBusinessModalActive(false)}
                        >
                            Anuluj
                        </Button>
                        <Button
                            additionalClass="btn-medium disabled-alt"
                            onClickFn={() => {
                                businessInvoiceDataSave();
                                setBusinessModalActive(false);
                                setisInvoiceLoading(true);
                            }}
                            isDisabled={invoiceCompanySubmitDisable}
                        >
                            Zapisz
                        </Button>
                    </ModalButtons>
                </Modal>
            </CSSTransition>
            {/* NOTIFY MODALS */}
            {isAboActivated && (
                <Modal
                    additionalClass="modal-notify"
                    onCloseClickFn={() => setisAboActivated(false)}
                >
                    <Title additionalClass="text-center">
                        Twój pakiet został aktywowany!
                    </Title>
                    <ModalDoodle>{doodleNotify_1}</ModalDoodle>
                </Modal>
            )}
            {isAboChanged && (
                <Modal
                    additionalClass="modal-notify"
                    onCloseClickFn={() => setisAboChanged(false)}
                >
                    <Title additionalClass="text-center">
                        Twój pakiet został zmieniony!
                    </Title>
                    <ModalDoodle>{doodleNotify_2}</ModalDoodle>
                </Modal>
            )}
            {isAboCanceled && (
                <Modal
                    additionalClass="modal-notify"
                    onCloseClickFn={() => setIsAboCanceled()}
                >
                    <Title additionalClass="text-center">
                        Twój pakiet został anulowany!
                    </Title>
                    <ModalDoodle>{doodleNotify_3}</ModalDoodle>
                </Modal>
            )}
            {/* TPAY MODAL */}
            <CSSTransition
                nodeRef={tpayModalRef}
                in={tpayModalActive}
                classNames="app-modal"
                timeout={300}
                unmountOnExit
                appear
            >
                <Modal additionalClass="modal-tpay" ref={tpayModalRef}>
                    <Tpay
                        user={user?.name + " " + user?.surname}
                        separator="-"
                        onError={() => setTpayModalErrorActive(true)}
                        onCancelFn={() => setTpayModalActive(false)}
                    />
                </Modal>
            </CSSTransition>
            {/* TPAY ERROR MODAL */}
            <CSSTransition
                nodeRef={tpayModalErrorRef}
                in={tpayModalErrorActive}
                classNames="app-modal"
                timeout={300}
                unmountOnExit
                appear
            >
                <Modal
                    additionalClass="modal-tpayError"
                    ref={tpayModalErrorRef}
                >
                    <TpayError
                        text={[
                            "Wystąpił błąd podczas próby dokonania płatności.",
                            "Prosimy o uzupełnienie danych użytkownika.",
                        ]}
                        onClose={() => setTpayModalErrorActive(false)}
                    />
                </Modal>
            </CSSTransition>
        </>
    );
};

export default Dashboard;
