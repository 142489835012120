import {useEffect, forwardRef} from 'react';

const Modal = forwardRef((props, ref) => {
    const {onCloseClickFn, additionalClass, children} = props;

    useEffect(() => {
        document.body.classList.add('modal-open');

        return () => {
            document.body.classList.remove('modal-open');
        }
    });

    return (
        <>
            <div className={'app-modal' + (additionalClass ? (' ' + additionalClass) : '')} ref={ref}>
                <div className="app-modal-container">
                    <div className="app-modal-dialog">
                        <div className="app-modal-content">
                            { onCloseClickFn && <button className="app-modal-close" type="button" onClick={onCloseClickFn}></button> }
                            {children}
                        </div>
                    </div>
                </div>
                <div className="app-modal-backdrop"></div>
            </div>
        </>
    )
});

export default Modal;