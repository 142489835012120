const SocialButton = ({icon}) => {
    return (
        <>
            <button className="btn-social" type="button">
                <img src={icon} alt=""/>
            </button>
        </>
    )
}

export default SocialButton;