const validator = (type, value) => {
    switch(type) {
        case 'lowercase':
            return /[a-z]/g.test(value);

        case 'uppercase':
            return /[A-Z]/g.test(value);
        
        case 'length':
            return value.length >= 8 && value.length <= 50 ? true : false;

        case 'special':
            return /[0-9!@#$%^&*)(+=_\-|\\}\]{["':;?/>.<,]/g.test(value);

        case 'email':
            return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);

        default:
            return false;
    }
}

export default validator;