const creditCardType = (number) => {
    const visa = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
    const mastercard = new RegExp("^5[1-5][0-9]{14}$");
    const mastercard2 = new RegExp("^2[2-7][0-9]{14}$");

    if (visa.test(number)) {
        return "VISA";
    }

    if (mastercard.test(number) || mastercard2.test(number)) {
        return "MASTERCARD";
    }

    return undefined;
};

export default creditCardType;
