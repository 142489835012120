import { useContext, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { StoreContext } from "../../../context/Store";

import Title from "../../headings/Title";
import Input from "../../inputs/Input";
import Button from "../../buttons/Button";

import validator from "../../../functions/validator";
import buildFormData from "../../../functions/buildFormData";

import BoxContainer from "../../containers/BoxContainer";

import SigningFormContainer from "../../containers/signing/SigningFormContainer";
import SigningValidationContainer from "../../containers/signing/SigningValidationContainer";
import SigningRulesContainer from "../../containers/signing/SigningRulesContainer";
import SigningRuleButton from "../../buttons/signing/SigningRuleButton";

const LoginForm = () => {
    const history = useHistory();

    const { api, setIsUserLogged } = useContext(StoreContext);
    const queryClient = useQueryClient();
    const [cookie, setCookie] = useCookies();

    const { isLoading: loginIsLoading, refetch: loginRefetch } = useQuery(
        "login",
        () =>
            api.post(
                `/login`,
                buildFormData({
                    email: inputValue.email,
                    password: inputValue.password,
                })
            ),
        {
            retry: false,
            enabled: false,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                setCookie("token", data.data.token, {
                    path: "/",
                    secure: true,
                });
                setIsUserLogged(true);
                queryClient.clear();
                history.replace("/dashboard");
            },
            onError: (error) => {
                if (error.response.status === 400) {
                    submitValidation();
                }
            },
        }
    );

    /* LOGIN VALIDATION STATE */
    const [loginValidation, setLoginValidation] = useState(false);

    /* INPUT VALUES */
    const [inputValue, setInputValue] = useState({
        email: "",
        password: "",
    });

    /* INPUT VALIDATORS */
    const [inputValidator, setInputValidator] = useState({
        email: {
            response: "",
        },
        password: {
            response: "",
        },
    });

    /* E-MAIL ON CHANGE HANDLER */
    const emailOnChangeHandler = (e) => {
        if (e.length > 0) {
            validator("email", e)
                ? setInputValidator({
                      ...inputValidator,
                      email: {
                          response: "success",
                      },
                  })
                : setInputValidator({
                      ...inputValidator,
                      email: {
                          response: "error",
                      },
                  });
        } else {
            setInputValidator({
                ...inputValidator,
                email: {
                    response: "",
                },
            });
        }

        setInputValue({ ...inputValue, email: e });
    };

    /* PASSWORD ON CHANGE HANDLER */
    const passwordOnChangeHandler = (e) => {
        if (e.length === 0) {
            setInputValidator({
                ...inputValidator,
                password: {
                    response: "",
                },
            });
        } else {
            if (e.length >= 8 && e.length <= 50) {
                setInputValidator({
                    ...inputValidator,
                    password: {
                        response: "success",
                    },
                });
            } else {
                setInputValidator({
                    ...inputValidator,
                    password: {
                        response: "error",
                    },
                });
            }
        }

        setInputValue({
            ...inputValue,
            password: e,
        });
    };

    /* ENTER KEY HANDLER */
    const onEnterKeyLogin = (e) => {
        const enterKey = e.charCode === 13 || e.keyCode === 13;

        if (!formSubmitDisable && enterKey) {
            e.currentTarget.blur();
            loginRefetch();
        }
    };

    /* ENABLE SUBMIT IF VALIDATION IS CORRECT */
    const formSubmitDisable =
        inputValidator.email.response === "success" &&
        inputValidator.password.response === "success"
            ? false
            : true;

    /* SUBMIT VALIDATION */
    const submitValidation = () => {
        setLoginValidation(true);

        //setInputValues({ email: { ...inputValues.email, valid: 'error' }, password: { ...inputValues.password, valid: 'error' } })
    };

    return (
        <>
            <BoxContainer contentClass="signing">
                <Title additionalClass="text-center">
                    Zaloguj się do How2 edu
                </Title>
                <SigningValidationContainer
                    isActive={loginValidation}
                    text="Nazwa użytkownika lub hasło są niepoprawne"
                >
                    <Button additionalClass="btn-blank btn-small">
                        Zapomniałeś hasło?
                    </Button>
                </SigningValidationContainer>
                <SigningFormContainer wrapperClass="signing">
                    <Input
                        key="Input"
                        type="email"
                        placeholder="wpisz e-mail"
                        inputValue={inputValue.email}
                        onChangeFn={(e) =>
                            emailOnChangeHandler(e.currentTarget.value)
                        }
                        onKeyPressFn={(e) => onEnterKeyLogin(e)}
                        isValidated={{
                            response: inputValidator.email.response,
                        }}
                    />
                    <Input
                        key="Input"
                        type="password"
                        placeholder="wpisz hasło"
                        inputValue={inputValue.password}
                        onChangeFn={(e) =>
                            passwordOnChangeHandler(e.currentTarget.value)
                        }
                        onKeyPressFn={(e) => onEnterKeyLogin(e)}
                        isValidated={{
                            response: inputValidator.password.response,
                        }}
                    />
                    <Button
                        key="Button"
                        isDisabled={formSubmitDisable}
                        onClickFn={() => loginRefetch()}
                    >
                        Zaloguj się
                    </Button>
                    <Button
                        key="Button"
                        additionalClass="btn-blank"
                        onClickFn={() => history.push("/remind_password")}
                    >
                        Nie pamiętasz hasła?
                    </Button>
                </SigningFormContainer>
                <SigningRulesContainer
                    text="Klikając „Zaloguj się” akceptujesz regulamin"
                    separator="i"
                >
                    <SigningRuleButton>How2 Edu</SigningRuleButton>
                    <SigningRuleButton>how2edu.app</SigningRuleButton>
                </SigningRulesContainer>
            </BoxContainer>
        </>
    );
};

export default LoginForm;
