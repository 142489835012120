import { useState, useRef } from "react";

import visa from "../../assets/images/tpay/tpay-visa.svg";
import mastercard from "../../assets/images/tpay/tpay-mastercard.svg";

const TpayInput = ({
    classNameControl,
    value,
    minValue,
    maxValue,
    placeholder,
    placeholderFocus,
    separator,
    minLength,
    maxLength,
    maskedCounter,
    icon,
    cardIcon,
    isDisabled,
    isValid,
    onChangeFn,
}) => {
    const backspaceKey = useRef(false);
    const valueTemp = useRef(value);
    const [placeholderState, setPlaceholderState] = useState(false);

    const onChangeHandler = (e) => {
        const valueTrim = e.currentTarget.value.replaceAll(separator, "");

        if (maxLength && typeof maxLength === "number") {
            if (valueTrim.length <= maxLength) {
                if (maskedCounter) {
                    valueTemp.current = valueTrim;

                    let maskedShift = 0;

                    for (let i = 0; i < valueTrim.length; i++) {
                        if (i !== 0 && i % maskedCounter === 0) {
                            valueTemp.current =
                                valueTemp.current.slice(0, i + maskedShift) +
                                separator +
                                valueTemp.current.slice(i + maskedShift);
                            maskedShift++;
                        }
                    }

                    onChangeFn(valueTemp.current);
                } else {
                    if (minValue || maxValue) {
                        if (minValue > parseInt(valueTrim)) {
                            if (minLength <= valueTrim.length || !minLength) {
                                if (backspaceKey.current) {
                                    onChangeFn(valueTrim);
                                } else {
                                    onChangeFn(minValue.toString());
                                }
                            } else {
                                onChangeFn(valueTrim);
                            }
                        } else if (maxValue < parseInt(valueTrim)) {
                            if (backspaceKey.current) {
                                onChangeFn(valueTrim);
                            } else {
                                onChangeFn(maxValue.toString());
                            }
                        } else {
                            onChangeFn(valueTrim);
                        }
                    } else {
                        onChangeFn(valueTrim);
                    }
                }
            }
        } else {
            onChangeFn(valueTrim);
        }
    };

    const numTest = (e) => {
        return (/[a-zA-Z!@#$%^&*)(+=_\-|\\}\]{["':;?/>.<,]/g.test(e.key) &&
            e.key !== "Backspace" &&
            e.key !== "ArrowLeft" &&
            e.key !== "ArrowRight") ||
            e.key === " "
            ? true
            : false;
    };

    const onKeyHandler = (e) => {
        if (e.key === "Backspace") {
            backspaceKey.current = true;
        } else {
            backspaceKey.current = false;
        }

        if (numTest(e)) {
            e.preventDefault();

            return false;
        }
    };

    return (
        <>
            <div className="tpay-control-wrapper">
                <input
                    type="text"
                    className={
                        "tpay-control" +
                        (isValid === "error" ? " error" : "") +
                        (isValid === "success" ? " success" : "") +
                        (classNameControl ? " " + classNameControl : "")
                    }
                    placeholder={
                        placeholderState ? placeholderFocus : placeholder
                    }
                    value={maskedCounter ? valueTemp.current : value}
                    pattern="\d*"
                    disabled={isDisabled}
                    onChange={(e) => onChangeHandler(e)}
                    onKeyDown={(e) => onKeyHandler(e)}
                    onKeyUp={(e) => onKeyHandler(e)}
                    onFocus={
                        placeholderFocus
                            ? () => setPlaceholderState(true)
                            : undefined
                    }
                    onBlur={
                        placeholderFocus
                            ? () => setPlaceholderState(false)
                            : undefined
                    }
                />
                {icon && (
                    <div className="tpay-control-icon">
                        <img src={icon} alt="" />
                    </div>
                )}
                {cardIcon !== undefined && isValid === "success" && (
                    <div className="tpay-control-card">
                        {cardIcon === "VISA" && <img src={visa} alt="VISA" />}
                        {cardIcon === "MASTERCARD" && (
                            <img src={mastercard} alt="MASTERCARD" />
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default TpayInput;
