const SigningRulesContainer = ({text, separator, children}) => {
    return (
        <>
            <div className="signing-rules">
                {
                    text
                    ? <p>{text}</p>
                    : ''
                }
                <div className="signing-rules-group">
                    {
                        children.length > 1
                        ? children.map((object, index) => {
                            let arrayMap = [];

                            if(index === 1 && children.length === 2) {
                                arrayMap.push(<p className="separator" key={'signing-separator_' + index}>{separator}</p>)
                            }

                            if(index >= 1 && children.length > 2) {
                                if(index === children.length - 1) {
                                    arrayMap.push(<p className="separator" key={'signing-separator_' + index}>{separator}</p>)
                                } else {
                                    arrayMap.push(<p className="separator comma" key={'signing-separator_' + index}>,</p>)
                                }
                            }
                            
                            arrayMap.push(<div key={'signing-rule_' + index}>{object}</div>);

                            return arrayMap;
                        })
                        : children
                    }
                </div>
            </div>
        </>
    )
}

export default SigningRulesContainer;