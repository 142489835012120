const Input = ({
    type,
    placeholder,
    inputValue,
    additionalClass,
    maxLength,
    isValidated,
    isThick,
    onChangeFn,
    onBlurFn,
    onFocusFn,
    onKeyPressFn,
}) => {
    const onChangeHandler = (e) => {
        const val = e.currentTarget.value;

        if (val.length <= maxLength) {
            onChangeFn(e);
        }
    };

    return (
        <>
            <div
                className={
                    "form-control-wrapper" +
                    (additionalClass ? " " + additionalClass : "") +
                    (isThick ? " is-thick" : "") +
                    (isValidated && isValidated.response === "error"
                        ? " control-error"
                        : "") +
                    (isValidated && isValidated.response === "success"
                        ? " control-success"
                        : "")
                }
            >
                <input
                    className="form-control"
                    type={type}
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={
                        maxLength
                            ? (e) => onChangeHandler(e)
                            : onChangeFn !== undefined
                            ? (e) => onChangeFn(e)
                            : undefined
                    }
                    onBlur={
                        onBlurFn !== undefined ? (e) => onBlurFn(e) : undefined
                    }
                    onFocus={
                        onFocusFn !== undefined
                            ? (e) => onFocusFn(e)
                            : undefined
                    }
                    onKeyPress={
                        onKeyPressFn !== undefined
                            ? (e) => onKeyPressFn(e)
                            : undefined
                    }
                />
            </div>
            {isValidated &&
            isValidated.response === "error" &&
            isValidated.text ? (
                <p className="form-control-message text-center">
                    {isValidated.text}
                </p>
            ) : (
                ""
            )}
        </>
    );
};

export default Input;
