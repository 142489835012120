import { useContext } from 'react';
import { StoreContext } from '../../../context/Store';
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie"

import Logo from "../../headings/Logo";
import Button from "../../buttons/Button";
import SocialButton from "../../buttons/SocialButton";

import DashboardFooter from "./DashboardFooter";

import facebookIcon from '../../../assets/images/social/fb-icon.png';
import instagramIcon from '../../../assets/images/social/ig-icon.png';
import twitterIcon from '../../../assets/images/social/tw-icon.png';



document.cookie = "cookiename=cookievalue"
const DashboardContainer = ({ children }) => {

    const { api, setIsUserLogged } = useContext(StoreContext);

    const history = useHistory();

    const [cookie, setCookie, removeCookie] = useCookies();

    const { data, refetch: logout } = useQuery(
        "logout",
        () =>
            api.post('/logout'),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                removeCookie("token");
                setIsUserLogged(false);
                history.replace("/");
            },
        }
    );

    return (
        <>
            <div className="app-dashboard">
                <div className="dashboard-main">
                    <Logo wrapperClass="dashboard-logo" />
                    <div className="dashboard-container">
                        <Button additionalClass="btn-medium btn-fixedSize" wrapperClass="dashboard-button" onClickFn={logout}>Wyloguj się</Button>
                        {children}
                    </div>
                </div>
                <div className="dashboard-bottom">
                    <DashboardFooter copyText="Copyright © How2 Edu 2022">
                        <SocialButton icon={facebookIcon} />
                        <SocialButton icon={instagramIcon} />
                        <SocialButton icon={twitterIcon} />
                    </DashboardFooter>
                </div>
            </div>
        </>
    )
}

export default DashboardContainer;