import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey:
            process.env.REACT_APP_AZURE_INSIGHTS_APPLICATION_KEY,
        /* ...Other Configuration Options... */
    },
});
appInsights.loadAppInsights();
appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
