import Title from "../../headings/Title";

const DashboardChildrensContainer = ({ titles, children }) => {
    return (
        <>
            <div className="dashboard-childrens">
                <Title
                    subtitle={titles.secondary}
                    additionalClass="text-center is-black is-large"
                >
                    {titles.primary}
                </Title>

                {children && (
                    <div className="dashboard-childrens-group">
                        {children.length > 1 ? (
                            children.map((object, index) => {
                                return (
                                    <div key={"dashboard-children_" + index}>
                                        {object}
                                    </div>
                                );
                            })
                        ) : children?.props?.panel ? (
                            <div className="full-width">{children}</div>
                        ) : (
                            <div>{children}</div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default DashboardChildrensContainer;
