import Wrapper from "../containers/Wrapper";

const Logo = ({wrapperClass}) => {
    return (
        <>
            <Wrapper
                condition={wrapperClass}
                wrapper={children => <div className={wrapperClass}>{children}</div>}
            >
                <div className="logo-default">
                    <svg id="Group_92" data-name="Group 92" width="50.039" height="50" viewBox="0 0 50.039 50">
                        <defs>
                            <clipPath id="clip-path">
                            <rect id="Rectangle_1086" data-name="Rectangle 1086" width="50.039" height="50" fill="none"/>
                            </clipPath>
                        </defs>
                        <g id="Group_91" data-name="Group 91" clipPath="url(#clip-path)">
                            <path id="Path_444" data-name="Path 444" d="M49.788,12C49.521,4.621,45.471.5,38.153.23,29.238-.093,20.3-.062,11.379.24,4.639.469.312,5.015.161,11.741Q-.144,25.285.134,38.84C.28,45.8,4.507,49.782,11.446,49.923c4.462.091,8.927.017,13.39.017v.027c4.464,0,8.929.085,13.39-.019,7.233-.168,11.434-3.988,11.634-11.17.248-8.919.249-17.86-.073-26.776" transform="translate(0 0)" fill="#0c6ff9"/>
                            <path id="Path_445" data-name="Path 445" d="M40.291,81.631a.633.633,0,0,1-.7-.7V73.519a.632.632,0,0,1,.7-.7h4.563a.556.556,0,0,1,.4.131.5.5,0,0,1,.138.381.482.482,0,0,1-.537.538H40.841v2.762h3.738a.558.558,0,0,1,.406.919.554.554,0,0,1-.406.132H40.841v2.9h4.013a.54.54,0,0,1,.4.138.623.623,0,0,1,0,.781.556.556,0,0,1-.4.131Z" transform="translate(-24.788 -45.592)" fill="#fff"/>
                            <path id="Path_446" data-name="Path 446" d="M60.5,81.6a2.585,2.585,0,0,1-1.406-.381,2.53,2.53,0,0,1-.944-1.1,3.918,3.918,0,0,1-.338-1.681,3.856,3.856,0,0,1,.338-1.687,2.6,2.6,0,0,1,.944-1.087,2.554,2.554,0,0,1,1.406-.387,2.4,2.4,0,0,1,1.381.394,1.908,1.908,0,0,1,.781,1.056h-.138v-3.5a.642.642,0,0,1,.163-.475.625.625,0,0,1,.463-.163.647.647,0,0,1,.468.163.629.629,0,0,1,.169.475v7.713a.648.648,0,0,1-.163.468.717.717,0,0,1-.925,0,.646.646,0,0,1-.162-.468v-1.1l.137.275a1.97,1.97,0,0,1-.781,1.082A2.372,2.372,0,0,1,60.5,81.6m.325-.975a1.622,1.622,0,0,0,.9-.25,1.648,1.648,0,0,0,.606-.738,2.919,2.919,0,0,0,.219-1.2,2.426,2.426,0,0,0-.475-1.625,1.572,1.572,0,0,0-1.25-.55,1.673,1.673,0,0,0-.912.244,1.613,1.613,0,0,0-.606.731,2.93,2.93,0,0,0-.219,1.2,2.451,2.451,0,0,0,.475,1.625,1.568,1.568,0,0,0,1.262.563" transform="translate(-36.196 -45.446)" fill="#fff"/>
                            <path id="Path_447" data-name="Path 447" d="M80.651,86.14a2.507,2.507,0,0,1-1.231-.275,1.706,1.706,0,0,1-.738-.819,3.348,3.348,0,0,1-.244-1.368v-3.2a.561.561,0,0,1,.625-.637.664.664,0,0,1,.469.156.627.627,0,0,1,.169.482V83.69a1.645,1.645,0,0,0,.3,1.087,1.185,1.185,0,0,0,.95.35,1.5,1.5,0,0,0,1.156-.481,1.8,1.8,0,0,0,.444-1.269v-2.9A.641.641,0,0,1,82.714,80a.659.659,0,0,1,.475-.156.561.561,0,0,1,.625.637v5q0,.637-.612.637a.594.594,0,0,1-.45-.169.647.647,0,0,1-.162-.469V84.415l.162.275a2.118,2.118,0,0,1-.8,1.075,2.25,2.25,0,0,1-1.3.375" transform="translate(-49.111 -49.988)" fill="#fff"/>
                            <path id="Path_448" data-name="Path 448" d="M22.531,49.127a1.138,1.138,0,0,1-.851-.306,1.193,1.193,0,0,1-.3-.87v-6.7a1.187,1.187,0,0,1,.3-.876,1.365,1.365,0,0,1,1.708,0,1.2,1.2,0,0,1,.294.876V43.6h3.641V41.255a1.2,1.2,0,0,1,.294-.876,1.146,1.146,0,0,1,.857-.3,1.13,1.13,0,0,1,.845.3,1.2,1.2,0,0,1,.294.876v6.7a1.208,1.208,0,0,1-.294.87,1.118,1.118,0,0,1-.845.306,1.134,1.134,0,0,1-.857-.306,1.209,1.209,0,0,1-.294-.87V45.485H23.682v2.465a1.223,1.223,0,0,1-.288.87,1.13,1.13,0,0,1-.863.306" transform="translate(-13.385 -25.094)" fill="#fff"/>
                            <path id="Path_449" data-name="Path 449" d="M49.921,53.574a3.9,3.9,0,0,1-1.789-.388,2.833,2.833,0,0,1-1.189-1.113,3.33,3.33,0,0,1-.425-1.715,3.6,3.6,0,0,1,.238-1.346A2.775,2.775,0,0,1,47.443,48a3.021,3.021,0,0,1,1.076-.638,4.229,4.229,0,0,1,1.4-.219,3.9,3.9,0,0,1,1.789.388,2.814,2.814,0,0,1,1.183,1.108,3.362,3.362,0,0,1,.419,1.721,3.607,3.607,0,0,1-.238,1.339,2.789,2.789,0,0,1-.682,1.02,2.953,2.953,0,0,1-1.076.639,4.248,4.248,0,0,1-1.4.219m0-1.64a1.037,1.037,0,0,0,1.008-.682,2.35,2.35,0,0,0,.144-.895,1.812,1.812,0,0,0-.326-1.208,1.054,1.054,0,0,0-.826-.369,1.12,1.12,0,0,0-.6.163,1.082,1.082,0,0,0-.413.507,2.294,2.294,0,0,0-.15.907,1.813,1.813,0,0,0,.326,1.2,1.057,1.057,0,0,0,.838.375" transform="translate(-29.124 -29.515)" fill="#fff"/>
                            <path id="Path_450" data-name="Path 450" d="M69.033,53.59a1.307,1.307,0,0,1-.738-.206,1.408,1.408,0,0,1-.5-.644l-1.7-4.118a1.306,1.306,0,0,1-.113-.707.769.769,0,0,1,.307-.513,1.145,1.145,0,0,1,.707-.194,1.065,1.065,0,0,1,.638.182,1.538,1.538,0,0,1,.451.707l1.2,3.153H69.02l1.264-3.392a1.022,1.022,0,0,1,.344-.494,1.083,1.083,0,0,1,1.133.006,1.086,1.086,0,0,1,.338.488l1.264,3.392h-.225l1.214-3.229a1.216,1.216,0,0,1,.451-.644,1.1,1.1,0,0,1,.588-.169.948.948,0,0,1,.651.2.775.775,0,0,1,.257.525,1.425,1.425,0,0,1-.131.7l-1.69,4.092a1.372,1.372,0,0,1-.488.651,1.4,1.4,0,0,1-1.483,0,1.462,1.462,0,0,1-.494-.651l-1.377-3.441h.914l-1.3,3.416a1.451,1.451,0,0,1-.475.67,1.224,1.224,0,0,1-.738.219" transform="translate(-41.302 -29.556)" fill="#fff"/>
                            <path id="Path_451" data-name="Path 451" d="M97.031,48.972a.894.894,0,0,1-1.039-1.014,1.244,1.244,0,0,1,.156-.626,2.766,2.766,0,0,1,.407-.538l2.19-2.29a4.852,4.852,0,0,0,.694-.9,1.548,1.548,0,0,0,.207-.751.841.841,0,0,0-.313-.713,1.47,1.47,0,0,0-.9-.238,2.752,2.752,0,0,0-.638.081,3.317,3.317,0,0,0-.726.282.953.953,0,0,1-.607.087.818.818,0,0,1-.451-.275.968.968,0,0,1-.225-.488.92.92,0,0,1,.081-.544.986.986,0,0,1,.451-.444,4.558,4.558,0,0,1,1.2-.451,5.708,5.708,0,0,1,1.245-.138,4.34,4.34,0,0,1,1.721.3,2.289,2.289,0,0,1,1.057.869,2.572,2.572,0,0,1,.363,1.408,3.424,3.424,0,0,1-.156,1.027,3.752,3.752,0,0,1-.5,1,7.363,7.363,0,0,1-.92,1.063l-2.027,2v-.575h3.091a.972.972,0,0,1,.707.238.916.916,0,0,1,.244.688.938.938,0,0,1-.244.695.956.956,0,0,1-.707.244Z" transform="translate(-59.964 -25.052)" fill="#fff"/>
                        </g>
                    </svg>
                </div>
            </Wrapper>
        </>
    )
}

export default Logo;