import { useHistory } from "react-router-dom";
import { forwardRef } from "react";

import Title from "../../headings/Title";

import RemindPasswordContainer from "../../containers/remindpassword/RemindPasswordContainer";
import RemindPasswordText from "../../containers/remindpassword/RemindPasswordText";

import doodle from "../../../assets/images/remindpassword/how2doodle_2.png";
import Button from "../../buttons/Button";

const RemindPasswordSuccessForm = forwardRef((props, ref) => {
    const history = useHistory();

    const userEmail = props.email;

    return (
        <>
            <div className="remindPassword-wrapper text-center" ref={ref}>
                <RemindPasswordContainer img={doodle}>
                    <Title>Instrukcja została wysłana</Title>
                    <RemindPasswordText>
                        <p>
                            Instrukcja do zresetowania Twojego hasła została
                            wysłana na mail
                            <br />
                            <strong>{userEmail}</strong>
                        </p>
                        <p>
                            Za kilka minut otrzymasz e-mail. Upewnij się, że
                            sprawdziłeś skrzynkę ze spamem również.
                        </p>
                    </RemindPasswordText>
                    <Button
                        wrapperClass="remindPassword-button success"
                        additionalClass="btn-medium"
                        onClickFn={() => history.goBack()}
                    >
                        Powrót do logowania
                    </Button>
                </RemindPasswordContainer>
            </div>
        </>
    );
});

export default RemindPasswordSuccessForm;
