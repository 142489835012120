import Button from "../../buttons/Button";
import Loader from "../../loader/Loader";

import banner_1 from "../../../assets/images/dashboard/subs/doodle-sub_1.png";
import banner_2 from "../../../assets/images/dashboard/subs/doodle-sub_2.png";
import banner_3 from "../../../assets/images/dashboard/subs/doodle-sub_3.png";

const DashboardSub = ({
  additionalClass,
  banner,
  price,
  onClickFn,
  children,
  isLoading,
}) => {
  const bannerPicture = (int) => {
    switch (int) {
      case 1:
        return banner_1;
      case 2:
        return banner_2;
      case 3:
        return banner_3;
      default:
        return undefined;
    }
  };

  return (
    <>
      <div
        className={
          "dashboard-sub" + (additionalClass ? " " + additionalClass : "")
        }
      >
        {bannerPicture(banner) && additionalClass !== "compact" && (
          <div className="dashboard-sub-header">
            <img src={bannerPicture(banner)} alt="" />
          </div>
        )}
        <div className="dashboard-sub-body">
          <div className="dashboard-sub-type">{children}</div>
          {price && additionalClass !== "compact" && (
            <div className="dashboard-sub-price">
              <p className="main-price">{Number(price.main).toFixed(0)} zł</p>
              {price.main / (price.discount / 30) != price.main && (
                <p className="discount-price">
                  (
                  {(Number(price.main) / (Number(price.discount) / 30)).toFixed(
                    0
                  )}{" "}
                  zł za miesiąc)
                </p>
              )}
            </div>
          )}
        </div>
        <div className="dashboard-sub-footer">
          {isLoading ? (
            <Loader />
          ) : (
            <Button
              additionalClass="btn-inverse highlight-alt btn-medium btn-fixedSize"
              onClickFn={onClickFn}
            >
              {" "}
              Wybierz
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default DashboardSub;
