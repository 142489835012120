import { useState, useContext } from "react";
import { StoreContext } from "../../../context/Store";

import Title from "../../headings/Title";
import Input from "../../inputs/Input";
import Button from "../../buttons/Button";
import { useQuery } from "react-query";

import validator from "../../../functions/validator";
import buildFormData from "../../../functions/buildFormData";

import SigningFormContainer from "../../containers/signing/SigningFormContainer";

const ResetPasswordCodeForm = ({ setResetFormActive, setConfirmation }) => {
    const { api } = useContext(StoreContext);

    const { refetch: confirmEmail } = useQuery(
        "confirm",
        () =>
            api.post(
                `/reset_password_confirm`,
                buildFormData({
                    email: inputValue.email,
                    token: inputValue.code,
                })
            ),
        {
            retry: false,
            enabled: false,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                setConfirmation({
                    email: inputValue.email,
                    token: inputValue.code,
                });
                setResetFormActive(true);
            },
        }
    );

    /* INPUT VALUES */
    const [inputValue, setInputValue] = useState({ email: "", code: "" });

    /* INPUT VALIDATORS */
    const [inputValidator, setInputValidator] = useState({
        email: { response: "" },
        code: { response: "" },
    });

    /* E-MAIL ON CHANGE HANDLER */
    const emailOnChangeHandler = (e) => {
        if (e.length > 0) {
            validator("email", e)
                ? setInputValidator({
                      ...inputValidator,
                      email: {
                          response: "success",
                      },
                  })
                : setInputValidator({
                      ...inputValidator,
                      email: {
                          response: "error",
                      },
                  });
        } else {
            setInputValidator({
                ...inputValidator,
                email: {
                    response: "",
                },
            });
        }

        setInputValue({ ...inputValue, email: e });
    };

    /* CODE ON CHANGE HANDLER */
    const codeOnChangeHandler = (e) => {
        if (e.length > 0) {
            setInputValidator({
                ...inputValidator,
                code: {
                    response: "success",
                },
            });
        } else {
            setInputValidator({
                ...inputValidator,
                code: {
                    response: "",
                },
            });
        }

        setInputValue({ ...inputValue, code: e });
    };

    /* ENTER KEY HANDLER */
    const onEnterKeyLogin = (e) => {
        const enterKey = e.charCode === 13 || e.keyCode === 13;

        if (!formSubmitDisable && enterKey) {
            e.currentTarget.blur();
            confirmEmail();
        }
    };

    /* ENABLE SUBMIT IF VALIDATION IS CORRECT */
    const formSubmitDisable =
        inputValidator.email.response === "success" &&
        inputValidator.code.response === "success"
            ? false
            : true;

    return (
        <>
            <Title additionalClass="text-center">
                Wpisz kod, który otrzymałeś na e-mail
            </Title>
            <SigningFormContainer wrapperClass="signing">
                <Input
                    key="Input"
                    type="email"
                    placeholder="wpisz e-mail"
                    inputValue={inputValue.email}
                    onChangeFn={(e) =>
                        emailOnChangeHandler(e.currentTarget.value)
                    }
                    onKeyPressFn={(e) => onEnterKeyLogin(e)}
                    isValidated={{ response: inputValidator.email.response }}
                />
                <Input
                    key="Input"
                    type="text"
                    placeholder="wpisz kod"
                    onChangeFn={(e) =>
                        codeOnChangeHandler(e.currentTarget.value)
                    }
                    onKeyPressFn={(e) => onEnterKeyLogin(e)}
                    isValidated={{ response: inputValidator.code.response }}
                />
                <Button
                    key="Button"
                    isDisabled={formSubmitDisable}
                    onClickFn={() => {
                        confirmEmail();
                    }}
                >
                    Wyślij
                </Button>
            </SigningFormContainer>
        </>
    );
};

export default ResetPasswordCodeForm;
