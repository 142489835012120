const DashboardFooter = ({copyText, children}) => {
    return (
        <>
            <div className="dashboard-footer text-center">
                <div className="dashboard-container">
                    {
                        children
                        && <div className="dashboard-social">
                            {
                                children.length > 1
                                ? children.map((object, index) => {
                                    return <div key={'social_' + index}>{object}</div>;
                                })
                                : <div>{children}</div>
                            }
                        </div>
                    }
                    {
                        copyText
                        && <p className="dashboard-copyrights">{copyText}</p>
                    }
                </div>
            </div>
        </>
    )
}

export default DashboardFooter;