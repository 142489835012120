const DashboardPanel = ({ title, switches, additionalClass, children }) => {
    const PanelSwitches = () => {
        const switchesArray = [];

        for (const singleSwitch in switches) {
            const object = switches[singleSwitch];

            switchesArray.push(
                <div
                    className="dashboard-switch-item"
                    key={"switch_" + singleSwitch}
                >
                    <button
                        className={
                            "dashboard-switch-radio" +
                            (object.active ? " is-active" : "")
                        }
                        type="button"
                        onClick={object.fn}
                        switchtarget={singleSwitch}
                    >
                        {object.name}
                    </button>
                </div>
            );
        }

        return switchesArray;
    };

    return (
        <>
            <div
                className={
                    "dashboard-panel" +
                    (additionalClass ? " " + additionalClass : "")
                }
            >
                {(title && additionalClass !== "dashboard-text") ||
                (switches && additionalClass !== "dashboard-text") ? (
                    <div
                        className={
                            "panel-heading" + (switches ? " has-switches" : "")
                        }
                    >
                        <div className="panel-heading-title h1">{title}</div>
                        {switches && (
                            <div className="panel-heading-switches">
                                <PanelSwitches />
                            </div>
                        )}
                    </div>
                ) : (
                    ""
                )}
                <div className="panel-body">
                    <div className="panel-body-inner">{children}</div>
                </div>
            </div>
        </>
    );
};

export default DashboardPanel;
