const DashboardTable = ({button, children}) => {
    return (
        <>
            <div className="dashboard-table-wrapper">
                <table className="dashboard-table">
                    <tbody>
                        
                            {
                                children.length>1
                                ? children.map((object, index) => {
                                    return <tr key={'table-row_' + index}>
                                        {object}
                                    </tr>
                                })
                                : children
                            }
                        
                    </tbody>
                </table>
                {
                    button
                    && <div className="dashboard-table-button">
                        <button className="btn-table" type="button" onClick={button.fn}>{button.text}</button>
                    </div>
                }
            </div>
        </>
    )
}

export default DashboardTable;