import { useState, useContext } from "react";
import { useQuery } from "react-query";

import { StoreContext } from "../context/Store";

const useHealth = () => {
    const { api } = useContext(StoreContext);

    const [isHealthError, setIsHealthError] = useState(null);

    const {
        data: healthData,
        isLoading: isHealthLoading,
        isRefetching: isHealthRefetching,
        refetch: refetchHealth,
    } = useQuery("health", () => api.get("/health"), {
        staleTime: Infinity,
        retry: false,
        refetchOnWindowFocus: false,
        onError: (error) => {
            if (error.message === "Network Error") {
                setIsHealthError(error.message);
            }
        },
    });

    return {
        healthData,
        isHealthLoading,
        isHealthRefetching,
        isHealthError,
        refetchHealth,
    };
};

export default useHealth;
