const DashboardPaymentContainer = ({text, children}) => {
    return (
        <>
            <div className="dashboard-payment">
                <div className="dashboard-payment-text">{text}</div>
                <div className="dashboard-payment-action">{children}</div>
            </div>
        </>
    )
}

export default DashboardPaymentContainer;