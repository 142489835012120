const SigningFormPasswordContainer = ({text, children}) => {
    return (
        <>
            <div className="signing-form-password-group">
                {
                    text
                    ? <p className="signing-form-password-label text-center">{text}</p>
                    : false
                }
                <div className="signing-form-password-validation">
                    {
                        children.length > 1
                        ? children.map((object, index) => {
                            return <div key={'registerPassword-input_' + index}>{object}</div>
                        })
                        : children
                    }
                </div>
            </div>
        </>
    )
}

export default SigningFormPasswordContainer;