import tpay_logo from "../../assets/images/tpay/tpay-logo.svg";

const TpayLogo = ({ logo }) => {
    return (
        <>
            <div className="tpay-logo">
                <img src={logo ? logo : tpay_logo} alt="tpay logo" />
            </div>
        </>
    );
};

export default TpayLogo;
