const ModalButtons = ({children}) => {
    return (
        <>
            <div className="app-modal-buttons">
                {
                    children.length > 1
                    ? children.map((object, index) => {
                        return <div className="app-modal-button" key={'modal-button_' + index}>{object}</div>
                    })
                    : <div className="app-modal-button">{children}</div>
                }
            </div>
        </>
    )
}

export default ModalButtons;