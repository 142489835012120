import { useState, forwardRef } from "react";

import Title from "../../headings/Title";
import Input from "../../inputs/Input";
import Button from "../../buttons/Button";

import validator from "../../../functions/validator";

import RemindPasswordContainer from "../../containers/remindpassword/RemindPasswordContainer";
import RemindPasswordText from "../../containers/remindpassword/RemindPasswordText";

import doodle from "../../../assets/images/remindpassword/how2doodle_1.svg";

const RemindPasswordForm = forwardRef((props, ref) => {
    const { onClickFn, emailValue, setEmailValue } = props;

    /* E-MAIL VALIDATOR */
    const [emailValidator, setEmailValidator] = useState("");

    /* E-MAIL ON CHANGE HANDLER */
    const emailOnChangeHandler = (e) => {
        if (e.length > 0) {
            validator("email", e)
                ? setEmailValidator("success")
                : setEmailValidator("error");
        } else {
            setEmailValidator("");
        }

        setEmailValue(e);
    };

    /* ENTER KEY HANDLER */
    const onEnterKeyLogin = (e) => {
        const enterKey = e.charCode === 13 || e.keyCode === 13;

        if (!formSubmitDisable && enterKey) {
            e.currentTarget.blur();
            onClickFn();
        }
    };

    /* ENABLE SUBMIT IF VALIDATION IS CORRECT */
    const formSubmitDisable =
        emailValue && emailValidator === "success" ? false : true;

    return (
        <>
            <div className="remindPassword-wrapper text-center" ref={ref}>
                <RemindPasswordContainer img={doodle}>
                    <Title>Zapomniałeś hasła?</Title>
                    <RemindPasswordText>
                        <p>
                            Wpisz swój adres e-mail,
                            <br />
                            wyślemy Ci link do zresetowania hasła
                        </p>
                    </RemindPasswordText>
                    <div className="remindPassword-form">
                        <Input
                            type="email"
                            placeholder="wpisz e-mail"
                            inputValue={emailValue}
                            isThick={true}
                            onChangeFn={(e) =>
                                emailOnChangeHandler(e.currentTarget.value)
                            }
                            onKeyPressFn={(e) => onEnterKeyLogin(e)}
                            isValidated={{ response: emailValidator }}
                        />
                        <Button
                            isDisabled={formSubmitDisable}
                            wrapperClass="remindPassword-button"
                            additionalClass="btn-medium"
                            onClickFn={onClickFn}
                        >
                            Wyślij
                        </Button>
                    </div>
                </RemindPasswordContainer>
            </div>
        </>
    );
});

export default RemindPasswordForm;
