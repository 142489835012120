const DashboardSubsContainer = ({ children }) => {


    const elements = children ? children.map((object, index) => {

        if (object?.props?.additionalClass === 'compact') {
            return <div className="full-width" key={'dashboard-sub_' + index}>{object}</div>
        } else {
            return <div key={'dashboard-sub_' + index}>{object}</div>
        }

    }) : null

    return (
        <>
            <div className="dashboard-subs-group">
                {elements}
            </div>
        </>
    )
}

export default DashboardSubsContainer;