const DashboardDataForm = ({children}) => {
    return (
        <>
            <div className="dashboard-data-form">
                {
                    children.length > 1
                    ? children.map((object, index) => {
                        return <div className="data-form-row" key={'data-form-row_' + index}>{object}</div>
                    })
                    : <div className="data-form-row">{children}</div>
                }
            </div>
        </>
    )
}

export default DashboardDataForm