import Button from "../buttons/Button";

const TpayError = ({ text, onClose }) => {
    return (
        <div className="tpay-error">
            <div className="error-icon"></div>
            {text ? (
                <div className="error-content">
                    {Array.isArray(text) ? (
                        text.length > 0 ? (
                            text.map((object, index) => {
                                return <p key={index}>{object}</p>;
                            })
                        ) : (
                            ""
                        )
                    ) : (
                        <p>{text}</p>
                    )}
                </div>
            ) : (
                ""
            )}
            <Button
                wrapperClass="error-button"
                additionalClass="btn-medium"
                onClickFn={onClose}
            >
                Zamknij
            </Button>
        </div>
    );
};

export default TpayError;
