import useHealth from "../hooks/useHealth";

const Health = () => {
    const {
        healthData,
        isHealthLoading,
        isHealthRefetching,
        isHealthError,
        refetchHealth,
    } = useHealth();

    return (
        <div
            style={{
                maxWidth: "330px",
                textAlign: "center",
                padding: "42px 15px 48px",
                margin: "0 auto",
            }}
        >
            <h1
                style={{
                    color: "black",
                    fontSize: "24px",
                    fontWeight: "bold",
                    lineHeight: "30px",
                }}
            >
                API STATUS:
            </h1>
            <p
                style={{
                    minHeight: "48px",
                    fontSize: "18px",
                    lineHeight: "24px",
                    marginTop: "12px",
                }}
            >
                {!isHealthLoading && !isHealthRefetching ? (
                    healthData?.data.connected === true ? (
                        <span style={{ color: "green" }}>
                            Connected to API
                            <br />({healthData?.status})
                        </span>
                    ) : (
                        <span style={{ color: "red", fontWeight: "bold" }}>
                            Can't connect to API
                            <br />(
                            {isHealthError
                                ? isHealthError
                                : healthData
                                ? healthData.status
                                : ""}
                            )
                        </span>
                    )
                ) : (
                    "Checking API status"
                )}
            </p>
            <button
                type="button"
                style={{
                    display: "block",
                    width: "140px",
                    background: "lightgray",
                    color: "black",
                    fontSize: "16px",
                    lineHeight: "20px",
                    border: "0",
                    borderRadius: "5px",
                    padding: "5px",
                    margin: "18px auto 0",
                    cursor: "pointer",
                }}
                onClick={refetchHealth}
            >
                Refresh
            </button>
        </div>
    );
};

export default Health;
