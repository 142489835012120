const ModalDoodle = ({children}) => {
    return (
        <>
            <div className="app-modal-doodle">
                <div className="doodle-banner">
                    <img src={children} alt=""/>
                </div>
                <div className="doodle-elipse"></div>
            </div>
        </>
    )
}

export default ModalDoodle;