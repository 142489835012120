import { useHistory, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";

import { pathPrev } from "./PaymentSuccess";

import Title from "../components/headings/Title";
import Button from "../components/buttons/Button";

import BoxContainer from "../components/containers/BoxContainer";
import BoxContainerDoodle from "../components/containers/BoxContainerDoodle";

import doodle from "../assets/images/payment/how2doodle_fail.svg";

const PaymentFail = () => {
    const history = useHistory();
    const location = useLocation();
    const cookies = useCookies("paymentRedirect")[0];
    const removeCookie = useCookies("paymentRedirect")[2];

    if (cookies.paymentRedirect !== "true") {
        history.push(pathPrev(location.pathname, 1));
    }

    const handleRedirect = () => {
        removeCookie("paymentRedirect", { path: "/" });

        history.push(pathPrev(location.pathname, 1));
    };

    return (
        <>
            <BoxContainer
                additionalClass="box-payment bg-creamy"
                contentClass="payment"
            >
                <BoxContainerDoodle>{doodle}</BoxContainerDoodle>
                <Title additionalClass="text-center">
                    Twoja płatność
                    <br />
                    nie powiodła się
                </Title>
                <Button
                    wrapperClass="box-button"
                    additionalClass="btn-large btn-shadow"
                    onClickFn={handleRedirect}
                >
                    powrót do strony
                </Button>
            </BoxContainer>
        </>
    );
};

export default PaymentFail;
