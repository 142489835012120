import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Signing from "./pages/Signing";
import ResetPassword from "./pages/ResetPassword";
import RemindPassword from "./pages/RemindPassword";
import Dashboard from "./pages/Dashboard";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentFail from "./pages/PaymentFail";

import Authorization from "./Authorization";
import PaymentForm from './pages/PaymentForm';

import Health from './pages/Health';

function App() {
  return (
    <Authorization>
      <Router>
        <Switch>
          <Route exact path="/" component={Signing} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/remind_password" component={RemindPassword} />
          <Route exact path="/reset_password" component={ResetPassword} />
          <Route exact path="/payment" component={PaymentForm} />
          <Route exact path="/dashboard/paymentConfirm" component={PaymentSuccess} />
          <Route exact path="/dashboard/paymentError" component={PaymentFail} />
          <Route exact path="/health" component={Health} />
          <Route
            path="*"
            children={
              <>
                <p>404</p>
                <p>Routes:</p>
                <p>/</p>
                <p>/signing-form</p>
                <p>/beginning</p>
                <p>/remind-password</p>
                <p>/remind-password-finish</p>
                <p>/dashboard</p>
              </>
            }
          />
        </Switch>

      </Router>
    </Authorization>
  )
}

export default App;