import Wrapper from "../containers/Wrapper";

const Button = ({
    wrapperClass,
    additionalClass,
    isHighlighted,
    isDisabled,
    onClickFn,
    children,
}) => {
    return (
        <>
            <Wrapper
                condition={wrapperClass}
                wrapper={(children) => (
                    <div className={wrapperClass}>{children}</div>
                )}
            >
                <button
                    className={
                        "btn-default" +
                        (additionalClass ? " " + additionalClass : "") +
                        (isHighlighted ? " highlighted" : "")
                    }
                    type="button"
                    disabled={isDisabled}
                    onClick={onClickFn}
                >
                    {children}
                </button>
            </Wrapper>
        </>
    );
};

export default Button;
