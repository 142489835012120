import { useRef } from "react";

import Button from "../buttons/Button";
import Checkbox from "../inputs/Checkbox";
import TpayInput from "./TpayInput";

import creditCardType from "../../functions/creditCardType";

import cvv_icon from "../../assets/images/tpay/tpay-card_cvv.svg";

const TpayForm = ({
    values,
    validations,
    separator,
    submit,
    isDisabled,
    onUpdate,
    onSubmit,
    onCancel,
}) => {
    const valid = useRef(false);
    const currentYear = new Date().getFullYear().toString().substr(-2);

    const onChangeSetter = (val, key, req) => {
        const valTrim = val.replaceAll(separator, "");

        if (req) {
            if (valTrim.length >= req) {
                valid.current = "success";
            } else if (valTrim.length > 0) {
                valid.current = "error";
            } else {
                valid.current = false;
            }
        } else {
            if (valTrim.length > 0) {
                valid.current = "success";
            } else {
                valid.current = false;
            }
        }

        onUpdate({
            value: { [key]: val },
            validation: { [key]: valid.current },
        });
    };

    return (
        <>
            <div className="tpay-form">
                <div className="tpay-fields">
                    <div className="tpay-field">
                        <TpayInput
                            value={values.number}
                            placeholder="Numer karty"
                            placeholderFocus="0000-0000-0000-0000"
                            separator={separator}
                            maxLength={16}
                            maskedCounter={4}
                            cardIcon={creditCardType(
                                values.number.replaceAll(separator, "")
                            )}
                            isDisabled={isDisabled}
                            isValid={validations.number}
                            onChangeFn={(e) => onChangeSetter(e, "number", 16)}
                        />
                    </div>
                    <div className="tpay-field date">
                        <TpayInput
                            classNameControl="center"
                            value={values.month}
                            minValue={1}
                            maxValue={12}
                            placeholder="MM"
                            maxLength={2}
                            isDisabled={isDisabled}
                            isValid={validations.month}
                            onChangeFn={(e) => onChangeSetter(e, "month")}
                        />
                    </div>
                    <div className="tpay-field date">
                        <TpayInput
                            classNameControl="center"
                            value={values.year}
                            minValue={currentYear}
                            placeholder="YY"
                            minLength={2}
                            maxLength={2}
                            isDisabled={isDisabled}
                            isValid={validations.year}
                            onChangeFn={(e) => onChangeSetter(e, "year", 2)}
                        />
                    </div>
                    <div className="tpay-field cvv">
                        <TpayInput
                            placeholder="CVV"
                            value={values.cvv}
                            icon={cvv_icon}
                            maxLength={3}
                            isDisabled={isDisabled}
                            isValid={validations.cvv}
                            onChangeFn={(e) => onChangeSetter(e, "cvv", 3)}
                        />
                    </div>
                    <div className="tpay-field check">
                        <Checkbox
                            className="bold tpay"
                            isDisabled={isDisabled}
                            isChecked={values.agreement}
                            onChangeFn={(e) =>
                                onUpdate({ value: { agreement: e } })
                            }
                        >
                            Akceptuję{" "}
                            <Button additionalClass="btn-inline" isDisabled={isDisabled}>
                                regulamin
                            </Button>{" "}
                            usług Tpay
                        </Checkbox>
                    </div>
                </div>
                <div className="tpay-buttons">
                    <div>
                        <Button
                            additionalClass="btn-medium btn-gray"
                            isDisabled={isDisabled}
                            onClickFn={() => onCancel()}
                        >
                            Anuluj
                        </Button>
                    </div>
                    <div>
                        <Button
                            additionalClass="btn-medium disabled-alt"
                            isHighlighted={isDisabled}
                            isDisabled={!submit || isDisabled}
                            onClickFn={() => onSubmit()}
                        >
                            Zapłać z Tpay
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TpayForm;
