const RemindPasswordContainer = ({img, children}) => {
    return (
        <>
            <div className="remindPassword-content">
                {children}
            </div>
            {
                img
                && <div className="remindPassword-doodle">
                    <div className="doodle-inner">
                        <img src={img} alt=""/>
                    </div>
                </div>
            }
        </>
    )
}

export default RemindPasswordContainer;