import { useHistory, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";

import Title from "../components/headings/Title";
import Button from "../components/buttons/Button";

import BoxContainer from "../components/containers/BoxContainer";
import BoxContainerDoodle from "../components/containers/BoxContainerDoodle";

import doodle from "../assets/images/payment/how2doodle_success.svg";

const pathSlice = (path) => (path.endsWith("/") ? path.slice(0, -1) : path);

export const pathPrev = (url, step) => {
    let urlSlice = pathSlice(url);
    let stepBackward =
        typeof step === "number" ? (step - 1 <= 0 ? 0 : step - 1) : step;
    let slashIndexes = [];

    for (let i = 0; i < urlSlice.length; i++) {
        if (urlSlice[i] === "/" && i !== 0) {
            slashIndexes.push(i);
        }
    }

    slashIndexes.sort((a, b) => b - a);

    if (stepBackward >= slashIndexes.length || stepBackward === "last") {
        if (slashIndexes.length > 1) {
            stepBackward = slashIndexes.length - 1;
        } else {
            stepBackward = 0;
        }
    }

    if (typeof stepBackward === "string" && stepBackward !== "last") {
        stepBackward = 0;
    }

    return urlSlice.substr(0, slashIndexes[stepBackward]);
};

const PaymentSuccess = () => {
    const history = useHistory();
    const location = useLocation();
    const cookies = useCookies("paymentRedirect")[0];
    const removeCookie = useCookies("paymentRedirect")[2];

    if (cookies.paymentRedirect !== "true") {
        history.push(pathPrev(location.pathname, 1));
    }

    const handleRedirect = () => {
        removeCookie("paymentRedirect", { path: "/" });

        history.push(pathPrev(location.pathname, 1));
    };

    return (
        <>
            <BoxContainer
                additionalClass="box-payment bg-creamy"
                contentClass="payment"
            >
                <BoxContainerDoodle>{doodle}</BoxContainerDoodle>
                <Title additionalClass="text-center">
                    Twoja płatność
                    <br />
                    poszła pomyślnie
                </Title>
                <Button
                    wrapperClass="box-button"
                    additionalClass="btn-large btn-shadow"
                    onClickFn={handleRedirect}
                >
                    powrót do strony
                </Button>
            </BoxContainer>
        </>
    );
};

export default PaymentSuccess;
