import JSEncrypt from "jsencrypt";

import getPublicKey from './getPublicKey'

const encodeCreditCardData = (data) => {
    const jsEncrypt = new JSEncrypt();
    jsEncrypt.setKey(getPublicKey());

    return jsEncrypt.encrypt(data);
};

export default encodeCreditCardData;
