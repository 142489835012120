import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { StoreContext } from "../../../context/Store";
import buildFormData from "../../../functions/buildFormData";

import Title from "../../headings/Title";
import Input from "../../inputs/Input";
import Checkbox from "../../inputs/Checkbox";
import Button from "../../buttons/Button";

import validator from "../../../functions/validator";

import SigningFormContainer from "../../containers/signing/SigningFormContainer";
import SigningFormPasswordContainer from "../../containers/signing/SigningFormPasswordContainer";
import SigningRulesContainer from "../../containers/signing/SigningRulesContainer";
import SigningRuleButton from "../../buttons/signing/SigningRuleButton";

const ResetPasswordForm = ({ confirmation }) => {
    const { api } = useContext(StoreContext);
    const history = useHistory();

    const { refetch: handleChangePassword } = useQuery(
        "reset",
        () =>
            api.post(
                `/reset_password_confirm`,
                buildFormData({
                    email: confirmation.email,
                    token: confirmation.token,
                    password: passwordValue.origin,
                    password2: passwordValue.repeated,
                })
            ),
        {
            retry: false,
            enabled: false,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                history.replace("/");
            },
        }
    );

    /* PASSWORD VALUES */
    const [passwordValue, setPasswordValue] = useState({
        origin: "",
        repeated: "",
    });

    /* PASSWORD VALIDATORS */
    const [passwordValidator, setPasswordValidator] = useState({
        uppercase: false,
        lowercase: false,
        length: false,
        special: false,
    });

    /* PASSWORD COMPARATORS */
    const [passwordComparator, setPasswordComparator] = useState({
        origin: "",
        repeated: "",
    });

    /* PASSWORD VALIDATION */
    useEffect(() => {
        const passwordObject = {
            lowercase: validator("lowercase", passwordValue.origin),
            uppercase: validator("uppercase", passwordValue.origin),
            length: validator("length", passwordValue.origin),
            special: validator("special", passwordValue.origin),
        };

        setPasswordValidator(passwordObject);
    }, [passwordValue.origin]);

    /* PASSWORD COMPARISION */
    useEffect(() => {
        if (
            passwordValue.origin.length > 0 ||
            passwordValue.repeated.length > 0
        ) {
            if (
                passwordValidator.uppercase &&
                passwordValidator.lowercase &&
                passwordValidator.length &&
                passwordValidator.special
            ) {
                if (passwordValue.repeated.length === 0) {
                    setPasswordComparator({
                        origin: "success",
                        repeated: "",
                    });
                } else {
                    if (passwordValue.origin === passwordValue.repeated) {
                        setPasswordComparator({
                            origin: "success",
                            repeated: "success",
                        });
                    } else {
                        setPasswordComparator({
                            origin: "error",
                            repeated: "error",
                        });
                    }
                }
            } else {
                setPasswordComparator({
                    ...passwordComparator,
                    origin: "error",
                });
            }
        } else {
            if (
                passwordValue.origin.length === 0 &&
                passwordValue.repeated.length === 0
            ) {
                setPasswordComparator({
                    origin: "",
                    repeated: "",
                });
            }
        }
    }, [
        passwordValue.origin,
        passwordValue.repeated,
        passwordValidator.uppercase,
        passwordValidator.lowercase,
        passwordValidator.length,
        passwordValidator.special,
    ]);

    /* ENTER KEY HANDLER */
    const onEnterKeyLogin = (e) => {
        const enterKey = e.charCode === 13 || e.keyCode === 13;

        if (!formSubmitDisable && enterKey) {
            e.currentTarget.blur();
            handleChangePassword();
        }
    };

    /* ENABLE SUBMIT IF VALIDATION IS CORRECT */
    const formSubmitDisable =
        passwordValidator.uppercase &&
        passwordValidator.lowercase &&
        passwordValidator.length &&
        passwordValidator.special &&
        passwordComparator.origin === "success" &&
        passwordComparator.repeated === "success"
            ? false
            : true;

    return (
        <>
            <Title additionalClass="text-center">Zresetuj hasło</Title>
            <SigningFormContainer
                wrapperClass="signing"
                additionalWrapperClass="signing-reset"
            >
                <Input
                    key="Input"
                    type="password"
                    placeholder="wpisz nowe hasło"
                    inputValue={passwordValue.origin}
                    onChangeFn={(e) =>
                        setPasswordValue({
                            ...passwordValue,
                            origin: e.currentTarget.value,
                        })
                    }
                    onKeyPressFn={(e) => onEnterKeyLogin(e)}
                    isValidated={{ response: passwordComparator.origin }}
                />
                <Input
                    key="Input"
                    type="password"
                    placeholder="powtórz hasło"
                    value={passwordValue.repeated}
                    onChangeFn={(e) =>
                        setPasswordValue({
                            ...passwordValue,
                            repeated: e.currentTarget.value,
                        })
                    }
                    onKeyPressFn={(e) => onEnterKeyLogin(e)}
                    isValidated={{
                        response: passwordComparator.repeated,
                        text: "Niezgodne hasła",
                    }}
                />
                <SigningFormPasswordContainer text="Hasło powinno zawierać">
                    <Checkbox
                        isDisabled={true}
                        isChecked={passwordValidator.uppercase}
                    >
                        wielką literę
                    </Checkbox>
                    <Checkbox
                        isDisabled={true}
                        isChecked={passwordValidator.length}
                    >
                        od 8 do 50 znaków
                    </Checkbox>
                    <Checkbox
                        isDisabled={true}
                        isChecked={passwordValidator.lowercase}
                    >
                        małą literę
                    </Checkbox>
                    <Checkbox
                        isDisabled={true}
                        isChecked={passwordValidator.special}
                    >
                        cyfrę lub znak specjalny
                    </Checkbox>
                </SigningFormPasswordContainer>
                <Button
                    key="Button"
                    isDisabled={formSubmitDisable}
                    onClickFn={() => handleChangePassword()}
                >
                    Zresetuj hasło
                </Button>
            </SigningFormContainer>
            <SigningRulesContainer
                text="Klikając „Zaloguj się” akceptujesz regulamin"
                separator="i"
            >
                <SigningRuleButton>How2 Edu</SigningRuleButton>
                <SigningRuleButton>how2edu.app</SigningRuleButton>
            </SigningRulesContainer>
        </>
    );
};

export default ResetPasswordForm;
