import LoginForm from "../components/forms/signing/LoginForm";

import useProtectedRoute from "../hooks/useProtetedRoute";

const Signing = () => {

    useProtectedRoute("/dashboard", true);

    return (
        <LoginForm />
    )

}
export default Signing;