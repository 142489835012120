import { useState, useEffect, useRef, useContext } from "react";
import { CSSTransition } from "react-transition-group";
import { StoreContext } from "../context/Store";

import BoxContainer from "../components/containers/BoxContainer";
import RemindPasswordForm from "../components/forms/remindpassword/RemindPasswordForm";
import RemindPasswordSuccessForm from "../components/forms/remindpassword/RemindPasswordSuccessForm";
import buildFormData from "../functions/buildFormData";

const RemindPassword = () => {
    const { api } = useContext(StoreContext);
    const [formActive, setFormActive] = useState(true);
    const [successActive, setSuccessActive] = useState(false);
    const [email, setEmail] = useState();

    const remindPasswordRef = useRef(null);
    const remindPasswordSuccessRef = useRef(null);

    useEffect(() => {
        if (!formActive) {
            setTimeout(() => {
                setSuccessActive(true);
            }, 300);
        }
    }, [formActive]);

    const handleSendEmail = async () => {
        api.post(
            `/reset_password`,
            buildFormData({
                email: email,
            })
        );
    };

    return (
        <>
            <BoxContainer
                additionalClass="bg-creamy"
                contentClass="remindPassword"
            >
                <CSSTransition
                    nodeRef={remindPasswordRef}
                    in={formActive}
                    classNames="remindPassword"
                    timeout={300}
                    unmountOnExit
                    appear
                >
                    <RemindPasswordForm
                        onClickFn={() => {
                            setFormActive(false);
                            handleSendEmail();
                        }}
                        ref={remindPasswordRef}
                        emailValue={email}
                        setEmailValue={(e) => setEmail(e)}
                    />
                </CSSTransition>
                <CSSTransition
                    nodeRef={remindPasswordSuccessRef}
                    in={successActive}
                    classNames="remindPassword-success"
                    timeout={300}
                    unmountOnExit
                    appear
                >
                    <RemindPasswordSuccessForm
                        email={email}
                        ref={remindPasswordSuccessRef}
                    />
                </CSSTransition>
            </BoxContainer>
        </>
    );
};

export default RemindPassword;
