import card_icon from "../../assets/images/tpay/tpay-card_icon.svg";
import card_chip from "../../assets/images/tpay/tpay-card_chip.svg";

const TpayCard = ({ values, user, separator, isSubmitting }) => {
    const numPad = (str) => (str.length < 2 ? "0" + str.toString() : str);

    const cardNumberValue = () => {
        const number = values.number.replaceAll(separator, "");

        return cardNumberPad(number, 16);
    };

    const cardNumberPad = (str, size) => {
        let tempStr = str;

        if (tempStr.length < size) {
            for (let i = 0; i < size - str.length; i++) {
                tempStr += "0";
            }
        }

        return cardNumberMask(tempStr);
    };

    const cardNumberMask = (str) => {
        let tempStr = str;
        let shift = 0;

        for (let i = 0; i < str.length; i++) {
            if (i !== 0 && i % 4 === 0) {
                tempStr =
                    tempStr.slice(0, i + shift) +
                    " " +
                    tempStr.slice(i + shift);
                shift++;
            }
        }

        return tempStr;
    };

    return (
        <>
            <div className={"tpay-card" + (isSubmitting ? " submitting" : "")}>
                <div className="card-icon">
                    <img src={card_icon} alt="" />
                </div>
                <div className="card-chip">
                    <img src={card_chip} alt="" />
                </div>
                <div className="card-data">
                    <div className="card-number">{cardNumberValue()}</div>
                    <div className="card-expiration">
                        <div className="expiration-symbol">
                            VALID
                            <br />
                            THRU
                        </div>
                        <div className="expiration-date">
                            {values.month ? numPad(values.month) : "00"}/
                            {values.year ? numPad(values.year) : "00"}
                        </div>
                    </div>
                </div>
                <div className="card-owner">
                    {user ? user : "Imię i nazwisko"}
                </div>
            </div>
        </>
    );
};

export default TpayCard;
