import { useState, useRef, useContext, useEffect } from "react";
import { StoreContext } from "../../../context/Store";
import { useQuery } from "react-query";

import { CSSTransition } from "react-transition-group";

import Modal from "../../modals/Modal";
import Button from "../../buttons/Button";
import Title from "../../headings/Title";

import DashboardSubsContainer from "../../containers/dashboard/DashboardSubsContainer";
import DashboardSub from "../../containers/dashboard/DashboardSub";

import avatar_1 from "../../../assets/images/dashboard/avatars/doodle-avatar_1.svg";
import avatar_2 from "../../../assets/images/dashboard/avatars/doodle-avatar_2.svg";
import avatar_3 from "../../../assets/images/dashboard/avatars/doodle-avatar_3.svg";

import doodle from "../../../assets/images/dashboard/how2doodle_children.svg";

import buildFormData from "../../../functions/buildFormData";
import Loader from "../../loader/Loader";

const DashboardChildren = ({ child, childrenList, setChildren, refetch, setIsAboChanged, setIsAboActivated, setIsAboCanceled }) => {

    const { api } = useContext(StoreContext);

    const [billings, setBillings] = useState();
    const [isLoading, setIsLoading] = useState(false);


    const { name, subscription, idUser } = child;
    const [avatar] = useState(Math.floor(Math.random() * (3 - 1)) + 1);

    const modalSubscriptionRef = useRef(null);
    const [modalSubscriptionActive, setModalSubscriptionActive] =
        useState(false);

    const modalBillingRef = useRef(null);
    const [modalBillingActive, setModalBillingActive] = useState(false);

    const { data: subscriptionsList } = useQuery(
        "subscriptionsList",
        () => api.get("/subscription/types"),
        {
            retry: false,

        }
    );
    useEffect(() => {
        setIsLoading(false)
    },[childrenList])


    const handleGetHistory = async () => {
        const data = await api.post(
            "/subscription/history",
            buildFormData({
                idUser: child.idUser,
            })
        );
        setBillings(data.data);
    };
    const handleActivateTrial = async () => {
        await api.post(
            "/subscription/trial",
            buildFormData({
                idUser: child.idUser,
            })
        );
        refetch();
        setIsAboActivated(true)
        setModalSubscriptionActive(false)
        setIsLoading(true)
    };

    const handleChooseSubscription = async (id, actionType) => {
        const res =await api.post(
            "/subscription/update",
            buildFormData({
                idSubscriptionType: id,
                idUser: child.idUser,
            })
        );
        if(res.status === 200){
            if(actionType === "changeSubscriptionType"){
                setIsAboChanged(true)
            }
            if(actionType === "activateSubscription"){
                setIsAboActivated(true)
            }
        }
        refetch();
        setModalSubscriptionActive(false)
        setIsLoading(true)
    };

    const handleCancel = async () => {
        const res = await api.post(
            "/subscription/update",
            buildFormData({
                idSubscriptionType: "1",
                idUser: child.idUser,
                action: "cancel",
            })
        );
        refetch();
        setIsLoading(true)
        if(res.status === 200){
                setIsAboCanceled(true)
        }
    };

    const handleResign = async () => {
        child.subscription.status = "expiring";
        setChildren(childrenList.map((child) => child));
        await api.post(
            "/subscription/remove",
            buildFormData({
                idUser: child.idUser,
            })
        );

    };

    const handleRenew = async () => {
        child.subscription.status = "active";
        setChildren(childrenList.map((child) => child));
        await api.post(
            "/subscription/activate",
            buildFormData({
                idUser: child.idUser,
            })
        );

    };
    /* DO USUNIECIA AVATARY BO BEDA DYNAMICZNIE */
    const avatarPicture = (int) => {
        switch (int) {
            case 1:
                return avatar_1;
            case 2:
                return avatar_2;
            case 3:
                return avatar_3;
            default:
                return avatar_1;
        }
    };

    const subscriptionText = (type) => {
        switch (subscription.status) {
            case "waiting":
                if (type === "status") {
                    return "Oczekujący";
                } else if (type === "info") {
                    return "Oczekuje na płatność";
                } else {
                    break;
                }
            case "inactive":
                if (type === "status") {
                    return "Nieaktywny";
                } else if (type === "info") {
                    return "Wygasł:";
                } else {
                    break;
                }
            case "active":
                if (type === "status") {
                    return "Aktywny";
                } else if (type === "info") {
                    if (Number(subscription.price) === 0) {
                        return "Ważny do:";
                    } else {
                        return "Odnowienie:";
                    }
                } else {
                    break;
                }
            case "expiring":
                if (type === "status") {
                    return "Wygasający";
                } else if (type === "info") {
                    return "Ważny do:";
                } else {
                    break;
                }
            default:
                break;
        }
    };

    const subscriptions = subscriptionsList?.data
        ? subscriptionsList?.data.map((sub, index) => {
              if (sub.subscriptionPrice !== "0.00") {
                  return (
                      <DashboardSub
                          key={index}
                          banner={index + 1}
                          price={{
                              main: sub.subscriptionPrice,
                              discount: sub.subscriptionLength,
                          }}
                          isLoading = {isLoading}
                          onClickFn={() => {
                              handleChooseSubscription(
                                sub.idSubscriptionType,
                                "changeSubscriptionType"
                              )
                              setIsLoading(true)
                          }}
                          child={child}
                      >
                          {sub.subscriptionName}
                      </DashboardSub>
                  );
              } else if (child.subscription.trialAvailable === 1) {
                  return (
                      <DashboardSub
                          additionalClass="compact"
                          key={index}
                          banner={index + 1}
                          price={{ main: sub.subscriptionPrice, discount: 6.5 }}
                          onClickFn={() => handleActivateTrial()}
                          child={child}
                      >
                          {sub.subscriptionName}
                      </DashboardSub>
                  );
              }
          })
        : null;

    const billingsList = billings
        ? billings.map((billing) => (
              <div
                  className="billing-table-body"
                  key={billing.idSubscriptionHistory}
              >
                  <div className="billing-table-row">
                      <div className="billing-table-columns">
                          <div className="billing-table-column column-1">
                              {billing.created_at
                                  .slice(0, 10)
                                  .split("-")
                                  .reverse()
                                  .join(".")}
                          </div>
                          <div className="billing-table-column column-2">
                              {billing.operation}
                          </div>
                          <div className="billing-table-column column-3">
                              {billing.active_from
                                  .slice(0, 10)
                                  .split("-")
                                  .reverse()
                                  .join(".")}{" "}
                              -{" "}
                              {billing.active_to
                                  .slice(0, 10)
                                  .split("-")
                                  .reverse()
                                  .join(".")}
                          </div>
                          <div className="billing-table-column column-4">
                              Karta (Tpay)
                          </div>
                      </div>
                      <div className="billing-table-sum">
                          {billing.amount} zł
                      </div>
                  </div>
              </div>
          ))
        : null;

    return (
        <>
            <div
                className={
                    "dashboard-children" +
                    (subscription.status ? " subscribed" : "")
                }
            >
                {!isLoading ? (<>
                <div className="dashboard-children-header">
                    <div className="dashboard-children-avatar">
                        <div
                            className="avatar-inner"
                            style={{
                                backgroundImage:
                                    "url(" + avatarPicture(avatar) + ")",
                            }}
                        ></div>
                    </div>
                    <div className="dashboard-children-name">{name}</div>
                </div>
                <div className="dashboard-children-body">
                    {subscription.status ? (
                        <div
                            className={
                                "dashboard-children-subscription" +
                                (subscription.status
                                    ? " " + subscription.status
                                    : "")
                            }
                        >
                            <div className="subscription-type">
                                {subscription.type}
                            </div>
                            {Number(subscription.price) > 0 ? (
                                <div className="subscription-cost">
                                    {Number(subscription.price).toFixed(0)} zł
                                </div>
                            ) : (
                                <div
                                    style={{ opacity: "0" }}
                                    className="subscription-cost"
                                >
                                    {Number(subscription.price).toFixed(0)} zł
                                </div>
                            )}
                            <div className="subscription-status">
                                <div className="status-badge">
                                    {subscriptionText("status")}
                                </div>
                                {subscription.status !== "inactive" ? <div className="status-info">
                                    {subscriptionText("info")}
                                    {subscription.active_to &&
                                    subscription.status !== "waiting"
                                        ? " " +
                                          subscription.active_to
                                              .slice(0, 10)
                                              .split("-")
                                              .reverse()
                                              .join(".")
                                        : ""}
                                </div> : null}
                            </div>
                        </div>
                    ) : (
                        <div className="dashboard-children-doodle">
                            <img src={doodle} alt="" />
                        </div>
                    )}
                </div>
                <div className="dashboard-children-footer">
                    {subscription.status === null && (
                        <>
                            <Button
                                additionalClass="btn-medium btn-fixedSize"
                                onClickFn={() =>
                                    setModalSubscriptionActive(true)
                                }
                            >
                                Wybierz pakiet
                            </Button>
                            {child.subscription.trialAvailable === 1 ? (
                                <Button
                                    additionalClass="btn-medium btn-fixedSize"
                                    onClickFn={() => handleActivateTrial()}
                                >
                                    Wypróbuj wersję trial
                                </Button>
                            ) : null}
                        </>
                    )}
                    {subscription.status === "inactive" && (
                        <Button
                            additionalClass="btn-medium btn-fixedSize"
                            onClickFn={() =>
                                handleChooseSubscription(child.subscription.id, "activateSubscription")
                            }
                        >
                            Aktywuj pakiet
                        </Button>
                    )}
                    {subscription.status === "expiring" && (
                        <Button
                            additionalClass="btn-medium btn-fixedSize"
                            onClickFn={() => handleRenew()}
                        >
                            Wznów pakiet
                        </Button>
                    )}
                    {subscription.status && (
                        <Button
                            additionalClass="btn-medium btn-fixedSize"
                            onClickFn={() => setModalSubscriptionActive(true)}
                        >
                            Zmień pakiet
                        </Button>
                    )}
                    {subscription.status && subscription.status !== "waiting" && (
                        <Button
                            additionalClass="btn-medium btn-fixedSize"
                            onClickFn={() => {
                                setModalBillingActive(true);
                                handleGetHistory();
                            }}
                        >
                            Szczegóły rozliczenia
                        </Button>
                    )}
                    {subscription.status === "active" &&(
                        <Button
                            additionalClass="btn-gray btn-medium btn-fixedSize"
                            onClickFn={() => handleResign()}
                        >
                            Zrezygnuj
                        </Button>
                    )}
                    {subscription.status === "waiting" && (
                        <Button
                            additionalClass="btn-gray btn-medium btn-fixedSize"
                            onClickFn={() => {
                                handleCancel();
                            }}
                        >
                            Anuluj
                        </Button>
                    )}
                </div></>) : <Loader/>}
            </div>
            <CSSTransition
                nodeRef={modalSubscriptionRef}
                in={modalSubscriptionActive}
                classNames="app-modal"
                timeout={300}
                unmountOnExit
                appear
            >
                <Modal
                    ref={modalSubscriptionRef}
                    onCloseClickFn={() => setModalSubscriptionActive(false)}
                >
                    <Title additionalClass="is-ultraLarge text-center">
                        Dostępne abonamenty
                    </Title>

                    <DashboardSubsContainer>
                        {/* <DashboardSub banner={1} price={{ main: (subscriptionsList?.data && subscriptionsList?.data[0].subscriptionPrice) }}>{subscriptionsList?.data && subscriptionsList?.data[0].subscriptionName}</DashboardSub>
                        <DashboardSub banner={2} price={{ main: 39, discount: 6.5 }}>Abonament<br />
                            półroczny</DashboardSub>
                        <DashboardSub banner={3} price={{ main: 48, discount: 4 }}>Abonament<br />
                            roczny</DashboardSub> */}
                        {/* <DashboardSub additionalClass="compact">Wypróbuj 7 dniową<br />
                            wersję trial</DashboardSub> */}
                        {subscriptions}
                    </DashboardSubsContainer>
                </Modal>
            </CSSTransition>
            <CSSTransition
                nodeRef={modalBillingRef}
                in={modalBillingActive}
                classNames="app-modal"
                timeout={300}
                unmountOnExit
                appear
            >
                <Modal
                    ref={modalBillingRef}
                    additionalClass="modal-billing"
                    onCloseClickFn={() => setModalBillingActive(false)}
                >
                    <Title additionalClass="is-ultraLarge text-center">
                        Szczegóły rozliczenia ({child.name})
                    </Title>
                    <div className="dashboard-billing-info">
                        <div className="billing-panel">
                            <div className="billing-panel-data">
                                <p>Twój abonament:</p>
                                <p>
                                    <strong>{subscription.type}</strong> za{" "}
                                    {subscription.price_per_month} zł/miesiąc
                                </p>
                            </div>
                            <div className="billing-panel-data">
                                <p>Następny rachunek:</p>
                                <p>
                                    {subscription?.active_to
                                        ?.slice(0, 10)
                                        .split("-")
                                        .reverse()
                                        .join(".")}
                                </p>
                            </div>
                        </div>
                        <div className="billing-panel-footnote text-small">
                            Opłaty za abonament są pobierane na początku każdego
                            okresu rozliczeniowego. Mogą pojawić się kilka dni
                            po dacie rozpoczęcia okresu rozliczeniowego.
                        </div>
                    </div>
                    {billingsList?.length > 0 ? (
                        <div className="dashboard-billing-table">
                            <div className="billing-table-header">
                                <div className="billing-table-row">
                                    <div className="billing-table-columns">
                                        <div className="billing-table-column column-1">
                                            Data
                                        </div>
                                        <div className="billing-table-column column-2">
                                            Opis
                                        </div>
                                        <div className="billing-table-column column-3">
                                            Okres usługi
                                        </div>
                                        <div className="billing-table-column column-4">
                                            Płatność
                                        </div>
                                    </div>
                                    <div className="billing-table-sum">
                                        Suma
                                    </div>
                                </div>
                            </div>

                            {billingsList}
                        </div>
                    ) : null}
                </Modal>
            </CSSTransition>
        </>
    );
};

export default DashboardChildren;
